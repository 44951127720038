<template>
    <div id="dingding-group-dw-content">
        <div class="qichat_drawer_content">
            <div class="access-item">
                <div class="name">
                    <span class="stepNamber">1</span>
                </div>
                <div class="des dingding-group-guide">
                    钉钉管理员完成<el-link
                        @click="gotoHelp"
                        target="_blank"
                        type="primary"
                        :underline="false"
                        ><span class="el-icon-thumb"></span
                        >《接入钉钉群聊指南》</el-link
                    >中的准备工作
                </div>
            </div>
            <div class="access-item">
                <div class="name">
                    <span class="stepNamber" style="margin-top: 28px">2</span>
                </div>
                <div class="des">
                    <div class="binding">
                        <p style="margin-right: 16px">绑定钉钉群模板及机器人</p>
                        <el-button
                            type="primary"
                            size="mini"
                            @click="bindDingding"
                            >+绑定</el-button
                        >
                    </div>
                    <div class="bindingCompany">
                        <div class="itemHeader">
                            <div class="companyItem">
                                <div class="companyItemName-group">
                                    钉钉机器人模板
                                </div>
                                <div class="companyItemTime">绑定时间</div>
                                <div class="companyItemOperition">操作</div>
                                <div class="bindBot">已绑定钉钉群</div>
                            </div>
                        </div>
                        <div class="itemContent">
                            <div
                                class="companyItem"
                                v-for="item in dingdingGroupList"
                                :key="item.id"
                            >
                                <div class="companyItemName-group">
                                    {{ item.groupTemplateName }}
                                </div>
                                <div class="companyItemTime">
                                    {{
                                        new Date(item.updateTime).Format(
                                            "yyyy-MM-dd hh:mm:ss"
                                        )
                                    }}
                                </div>
                                <div class="companyItemOperition">
                                    <el-link
                                        type="primary"
                                        @click="showDingdingGroupBindInfo(item)"
                                        class="view"
                                        >查看</el-link
                                    >
                                    <!-- 解绑 -->
                                    <el-link
                                        v-if="item.bindType != 0"
                                        @click="
                                            postDingdingGroupUnbind(item.id)
                                        "
                                        type="primary"
                                        class="unbind"
                                        >解绑</el-link
                                    >
                                </div>
                                <div class="bindBot">
                                    <span
                                        v-if="
                                            !(
                                                item.bindBot == null ||
                                                (item.bindBot &&
                                                    item.bindBot.count == 0)
                                            ) && item.conversationCount == 0
                                        "
                                        class="bindBotName"
                                    >
                                        暂未绑定钉钉群
                                    </span>
                                    <span
                                        v-if="
                                            !(
                                                item.bindBot == null ||
                                                (item.bindBot &&
                                                    item.bindBot.count == 0)
                                            ) && item.conversationCount != 0
                                        "
                                        class="bindBotName"
                                    >
                                        已绑定{{ item.conversationCount }}个群
                                    </span>
                                    <span
                                        style="color: #366AFF"
                                        v-if="
                                            !(
                                                item.bindBot == null ||
                                                (item.bindBot &&
                                                    item.bindBot.count == 0)
                                            )
                                        "
                                        class="editBindBot el-icon-s-tools"
                                        @click="
                                            editDingdingGroupList(
                                                item.groupTemplateId,
                                                item
                                            )
                                        "
                                    ></span>
                                    <el-button
                                        v-if="
                                            item.bindBot == null ||
                                            (item.bindBot &&
                                                item.bindBot.count == 0)
                                        "
                                        size="mini"
                                        type="primary"
                                        @click="bindBotV2('eWx', 'add', item)"
                                        >请绑定机器人</el-button
                                    >
                                </div>
                            </div>
                        </div>
                        <div
                            class="itemContent"
                            v-show="
                                dingdingGroupList == null ||
                                dingdingGroupList.length == 0
                            "
                        >
                            <br />
                            <p class="null" style="text-align: center">暂 无</p>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div class="access-item">
                <div class="name">
                    <span class="stepNamber">3</span>
                </div>
                <div class="des">在钉钉群中开启对应的群模板即可</div>
            </div>
            <div class="access-item">
                <div class="name">
                    <span class="stepNamber">4</span>
                </div>
                <div class="des">绑定完成</div>
            </div>
        </div>
        <el-dialog
            title="绑定钉钉群模板"
            :visible.sync="dialogdingdingGroupFormVisible"
            :close-on-click-modal="false"
            append-to-body="true"
            @open="resetStatus"
        >
            <el-form ref="bindForm" :model="dingdingBindForm">
                <el-form-item
                    label="钉钉群模板名称"
                    :label-width="bindingformLabelWidth"
                    prop="groupModalName"
                >
                    <el-input
                        v-model="dingdingBindForm.groupModalName"
                        placeholder="请输入钉钉群模板名称"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="钉钉群模板ID"
                    :label-width="bindingformLabelWidth"
                    prop="groupModalId"
                >
                    <el-input
                        v-model="dingdingBindForm.groupModalId"
                        placeholder="请输入钉钉群模板ID"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="群机器人ID"
                    :label-width="bindingformLabelWidth"
                    prop="groupBotId"
                >
                    <el-input
                        v-model="dingdingBindForm.groupBotId"
                        placeholder="请输入群机器人ID"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="corpId"
                    label="在钉钉开放平台【首页】中找到企业ID，填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingBindForm.corpId"
                        placeholder="请输入企业ID"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="agentId"
                    label="在钉钉管理后台【应用开发】->【H5微应用】中点击要绑定的自建H5应用，点击自建应用可见到AgentID、AppKey和AppSecret，分别填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingBindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                    <span
                        v-if="warningVisible"
                        style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        "
                        class="warning-text"
                        >*该应用已绑定，不能重复绑定</span
                    >
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="appKey"
                >
                    <el-input
                        v-model="dingdingBindForm.appKey"
                        placeholder="请输入AppKey"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="secret"
                >
                    <el-input
                        v-model="dingdingBindForm.secret"
                        placeholder="请输入Secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogdingdingGroupFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="getAuthUrlDingding"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 查看已绑定钉钉群模板对话框 -->
        <el-dialog
            style="overflow-y: auto"
            title="已绑定钉钉群模板"
            append-to-body="true"
            :close-on-click-modal="false"
            :visible.sync="dingdingGroupInfoDialogVisible"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingBindRules"
                :model="dingdingBindForm"
            >
                <el-form-item label="钉钉群模板名称">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入钉钉群模板名称"
                        v-model="dingdingBindForm.groupTemplateName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="钉钉群模板ID">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入钉钉群模板ID"
                        v-model="dingdingBindForm.groupTemplateId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="群机器人ID">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入群机器人ID"
                        v-model="dingdingBindForm.groupRobotCode"
                    ></el-input>
                </el-form-item>
                <el-form-item label="corpId">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入corpId"
                        v-model="dingdingBindForm.corpId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentID">
                    <el-input
                        disabled="disabled"
                        v-model="dingdingBindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppKey">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入AppKey"
                        v-model="dingdingBindForm.appKey"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Secret">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入Secret"
                        v-model="dingdingBindForm.secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdingGroupInfoDialogVisible = false"
                    >关 闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dingdingGroupList: [],
            dingdingGuideUrl: "https://showdoc.askbot.cn/web/#/5?page_id=41", // 钉钉群接入指南地址
            dialogdingdingGroupFormVisible: false, // 添加or编辑钉钉群模板表单
            warningVisible: false,
            bindingformLabelWidth: "",
            dingdingBindForm: {
                groupModalName: "",
                groupModalId: "",
                groupBotId: "",
                agentId: "",
                corpId: "",
                secret: "",
                appKey: "",
            },
            dingdingBindRules: {
                groupModalName: [
                    {
                        required: true,
                        message: "请输入钉钉群模板名称",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                groupModalId: [
                    {
                        required: true,
                        message: "请输入钉钉群模板ID",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                groupBotId: [
                    {
                        required: true,
                        message: "请输入群机器人ID",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                corpId: [
                    {
                        required: true,
                        message: "请输入企业ID",
                        trigger: "blur",
                    },
                    { max: 36, message: "长度最长为36个字符", trigger: "blur" },
                ],
                agentId: [
                    {
                        required: true,
                        message: "请输入AgentID",
                        trigger: "blur",
                    },
                    { max: 32, message: "长度最长为64个字符", trigger: "blur" },
                ],
                secret: [
                    {
                        required: true,
                        message: "请输入secret",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                appKey: [
                    {
                        required: true,
                        message: "请输入appKey",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
            },
            dingdingGroupInfoDialogVisible: false,
            activeId: "",
        };
    },
    methods: {
        // 跳转到帮助页面
        gotoHelp() {
            this.$router.push({
                name: "help",
                params: {
                    helpSrc:
                        this.dingdingGuideUrl,
                },
            });
        },
        editDingdingGroupList(id, item) {
            this.activeId = id;
            this.$emit("editDingdingGroupList", id, JSON.stringify(item));
        },
        bindBotV2(appType, handleType, item) {
            this.$emit("bindBotV2", appType, handleType, item);
        },
        // 获取钉钉群绑定列表
        getDingdingGroupBindList(isGrouplist) {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "7").then(
                (res) => {
                    if (res.code === "0") {
                        this.dingdingGroupList = [...res.data];
                        if (isGrouplist) {
                            this.dingdingGroupList.forEach((item) => {
                                if (item.groupTemplateId == this.activeId) {
                                    this.$emit(
                                        "editDingdingGroupList",
                                        this.activeId,
                                        JSON.stringify(item)
                                    );
                                }
                            });
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        resetStatus() {
            this.warningVisible = false;
        },
        // 绑定钉钉群模板
        bindDingding() {
            this.dialogdingdingGroupFormVisible = true;
            this.dingdingBindForm = {
                groupModalName: "",
                groupModalId: "",
                groupBotId: "",
                agentId: "",
                corpId: "",
                secret: "",
                appKey: "",
            };
        },
        // 查看钉钉群已绑定企业信息
        showDingdingGroupBindInfo(item) {
            this.dingdingGroupInfoDialogVisible = true;
            this.dingdingBindForm = item;
        },
        // 钉钉群应用解绑
        postDingdingGroupUnbind(id) {
            this.$confirm("解绑后，将无法继续使用，是否继续解绑？")
                .then((_) => {
                    this.FetchPost(this.requestUrl.dingdingBind.unBind, {
                        bindId: id,
                    }).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("解绑成功");
                            this.getDingdingGroupBindList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        // 绑定钉钉群模板
        getAuthUrlDingding() {
            this.dialogdingdingGroupFormVisible = true;
            // this.$refs.bindForm.validate((valid) => {
            //     if (valid) {
            //         alert("submit!");
            //     } else {
            //         console.log("error submit!!");
            //         return false;
            //     }
            // });
            if (
                this.dingdingBindForm.groupModalName &&
                this.dingdingBindForm.groupModalId &&
                this.dingdingBindForm.groupBotId &&
                this.dingdingBindForm.agentId &&
                this.dingdingBindForm.corpId &&
                this.dingdingBindForm.secret &&
                this.dingdingBindForm.appKey
            ) {
                this.FetchPost(
                    this.requestUrl.dingdingGroup.dingtalkGroupTemplateBind,
                    {
                        groupTemplateName:
                            this.dingdingBindForm.groupModalName.trim(),
                        agentName: this.dingdingBindForm.groupModalName.trim(),
                        groupTemplateId:
                            this.dingdingBindForm.groupModalId.trim(),
                        groupRobotCode: this.dingdingBindForm.groupBotId.trim(),
                        agentId: this.dingdingBindForm.agentId.trim(),
                        corpId: this.dingdingBindForm.corpId.trim(),
                        appSecret: this.dingdingBindForm.secret.trim(),
                        appKey: this.dingdingBindForm.appKey,
                        dingTalkType: 7,
                    }
                ).then((res) => {
                    if (res.code === "0") {
                        this.dingdingBindForm = {
                            groupModalName: "",
                            groupModalId: "",
                            groupBotId: "",
                            agentId: "",
                            corpId: "",
                            secret: "",
                            appKey: "",
                        };
                        this.authUrl = res.data;
                        this.$message.success("绑定成功");
                        this.dialogdingdingGroupFormVisible = false;
                        this.getDingdingGroupBindList();
                    } else if (res.code === "0OPBE0006") {
                        this.warningVisible = true;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            } else {
                this.$message.error("绑定信息不能为空，请检查！");
            }
        },
    },
    mounted() {
        this.getDingdingGroupBindList();
    },
};
</script>
<style lang="less" scoped>
#dingding-group-dw-content {
    .dingding-group-guide {
        display: flex;
        justify-content: flex-start;
        flex-direction: row !important;
        flex-wrap: wrap;
        height: 22px;
        line-height: 22px;
        .el-icon-thumb {
            display: inline-block;
            margin-left: 8px;
            transform: rotate(90deg);
        }
    }
}
</style>