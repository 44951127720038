var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"staff-customer"},[_c('div',{staticClass:"staff-customer-input"},[_c('el-input',{attrs:{"placeholder":"请输入部门/员工名称，回车搜索","size":"small"},on:{"input":_vm.searchInit},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchStaff.apply(null, arguments)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_vm._l((_vm.treeData),function(item,index){return _c('div',{key:'ind' + index},[_c('el-tree',{key:"selectOrganizationTreeLazy",ref:item.id + 'TreeLazy',refInFor:true,staticClass:"filter-tree",attrs:{"data":[item],"id":"selectOrganizationTreeLazy","node-key":"id","default-expanded-keys":_vm.defaultExpandedKeysMember,"expand-on-click-node":false},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"custom-tree-node",on:{"click":function($event){return _vm.onNodeClick(data, '', item, node)}}},[_c('span',{staticClass:"custom-tree-node-left",class:data.dataType === 'hasNext' ?'loadMore':''},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(data.type == 1),expression:"data.type == 1"}],class:[
                            'el-icon-caret-right',
                            data.showChildren ? 'down' : 'right',
                        ],on:{"click":function($event){$event.stopPropagation();return _vm.nodeExpand(data, node)}}}),(
                            (item.originData &&
                            item.originData.wechatBotDTO &&
                            item.originData.wechatBotDTO.bindType === 0 &&
                            item.originData.wechatBotDTO.wechatType === 0) || data.isWeWorkThirdData
                        )?_c('span',{staticClass:"data-label"},[_c('open-data',{attrs:{"type":data.type == 1
                                    ? 'departmentName'
                                    : 'userName',"openid":data.label}})],1):_c('span',{staticClass:"data-label"},[_vm._v(_vm._s(data.label))])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(data.checked),expression:"data.checked"}]},[_c('i',{class:['el-icon-check']})])])}}],null,true)})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }