<template>
    <div id="link">
        <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <i class="arsenal_icon arsenaljiekou"></i>
                <span class="page-name">{{activePageInfo.headerName}}</span>
            </div>
        </page-top-header>
        <div class="link-content">
            <bot-link />
        </div>
    </div>
</template>
<script>
import BotLink from './BotLinkV2.vue';
import pageTopHeader from '../../components/page-top-header.vue'
export default {
    data() {
        return {
            activePageInfo: {
                headerName: "渠道接入",
            },
        };
    },
    components: {BotLink,pageTopHeader},
    methods: {},
    mounted() {},
};
</script>
<style lang="less">
@import "./../../assets/less/Webhook/Webhook.less";

#link {}
</style>



