<template>
    <div id="botLink">
        <div v-if="false" class="linkType">接入渠道</div>
        <!-- 企业微信解绑对话框 -->
        <el-dialog title="解绑提示" :visible.sync="dialogVisible" width="30%">
            <span
                >解绑后，该企业的自建应用将无法继续使用该机器人，是否继续解绑？</span
            >
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="danger" @click="postUnbind(bindId)"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 查看已绑定企业飞书 -->
        <el-dialog title="已绑定企业" :close-on-click-modal="false" :visible.sync="feishuInfoDialogVisible">
            <el-form ref="”bindForm“" :rules="bindRules" :model="feishuBindForm">
                <el-form-item label="企业名称">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业名称"
                        v-model="feishuBindForm.corpName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="企业ID">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业ID"
                        v-model="feishuBindForm.corpId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentName">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入AgentName"
                        v-model="feishuBindForm.agentName"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="feishuInfoDialogVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
        <!-- 查看已绑定企业微信企业对话框 -->
        <el-dialog title="已绑定企业" :close-on-click-modal="false" :visible.sync="infoDialogVisible">
            <el-form ref="”bindForm“" :rules="bindRules" :model="bindForm">
                <el-form-item label="企业名称">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业名称"
                        v-model="bindForm.corpName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="企业ID">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业ID"
                        v-model="bindForm.corpId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentID">
                    <el-input
                        disabled="disabled"
                        v-model="bindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentName">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入AgentName"
                        v-model="bindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Secret">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入Secret"
                        v-model="bindForm.secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="infoDialogVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
        <!-- 查看已绑定钉钉企业对话框 -->
        <el-dialog
            style="overflow-y: auto"
            title="已绑定企业"
            :close-on-click-modal="false"
            :visible.sync="dingdinInfoDialogVisible"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingBindRules"
                :model="dingdingBindForm"
            >
                <el-form-item label="企业名称">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业名称"
                        v-model="dingdingBindForm.corpName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="企业ID">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业ID"
                        v-model="dingdingBindForm.corpId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentID">
                    <el-input
                        disabled="disabled"
                        v-model="dingdingBindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentName">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入AgentName"
                        v-model="dingdingBindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppKey">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入AppKey"
                        v-model="dingdingBindForm.appKey"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Secret">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入Secret"
                        v-model="dingdingBindForm.secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdinInfoDialogVisible = false"
                    >关 闭</el-button
                >
            </div>
        </el-dialog>
        <!-- 查看已绑定钉钉群对话框 -->
        <!-- <el-dialog
            style="overflow-y: auto"
            title="已绑定信息"
            :close-on-click-modal="false"
            :visible.sync="dingdinGroupInfoDialogVisible"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingBindRules"
                :model="dingdingGroupBindForm"
            >
                <el-form-item label="企业名称">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业名称"
                        v-model="dingdingGroupBindForm.corpName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="企业ID">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业ID"
                        v-model="dingdingGroupBindForm.corpId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentID">
                    <el-input
                        disabled="disabled"
                        v-model="dingdingGroupBindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                </el-form-item>
                <el-form-item label="机器人名称">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入机器人名称"
                        v-model="dingdingGroupBindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppKey">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入AppKey"
                        v-model="dingdingGroupBindForm.appKey"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Secret">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入Secret"
                        v-model="dingdingGroupBindForm.secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdinGroupInfoDialogVisible = false"
                    >关 闭</el-button
                >
            </div>
        </el-dialog> -->
        <!-- 查看已绑定公众号对话框 -->
        <el-dialog title="公众号详情" :close-on-click-modal="false" :visible.sync="wechatMpShowDialogVisible">
            <el-form
                ref="”bindForm“"
                :rules="bindRules"
                :model="wechatMpBindForm"
            >
                <el-form-item label="公众号名称">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入公众号名称"
                        v-model="wechatMpBindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppID">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入AppID"
                        v-model="wechatMpBindForm.agentId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppSecret">
                    <el-input
                        disabled="disabled"
                        v-model="wechatMpBindForm.secret"
                        placeholder="请输入AppSecret"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item label="授权URL">
          <el-input disabled="disabled" v-model="wechatMpBindForm.authUrl" placeholder="授权URL"></el-input>
        </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="wechatMpShowDialogVisible = false"
                    >关 闭</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            title="请选择授权方式"
            :close-on-click-modal="false"
            :visible.sync="wechatMpAuthDialog"
            width="30%"
        >
            <div class="authType">
                <div class="authTypeItem" @click="selectAuthType(0, $event)">
                    <img src="../../assets/images/u20440.png" />
                    <p>第三方授权(推荐)</p>
                </div>
                <div class="authTypeItem" @click="selectAuthType(1)">
                    <img src="../../assets/images/u20444.png" />
                    <p>开发者授权</p>
                </div>
            </div>
        </el-dialog>
        <!-- 开发者授权微信公众号 -->
        <el-dialog title="开发者授权微信公众号" :close-on-click-modal="false" :visible.sync="developerDialog">
            <el-form
                ref="”bindForm“"
                :rules="bindRules"
                :model="wechatMpBindForm"
            >
                <el-form-item label="公众号名称">
                    <el-input
                        placeholder="请输入公众号名称"
                        v-model="wechatMpBindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppID">
                    <el-input
                        placeholder="请输入AppID"
                        v-model="wechatMpBindForm.agentId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppSecret">
                    <el-input
                        v-model="wechatMpBindForm.secret"
                        placeholder="请输入AppSecret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="developerDialog = false">关 闭</el-button>
                <el-button @click="bindWechatBySecret">确 定</el-button>
            </div>
        </el-dialog>
        <!--添加网站信息-->
        <el-dialog title="添加网站信息" :close-on-click-modal="false" :visible.sync="addWebsiteDialogVisible">
            <el-form
                ref="”bindForm“"
                :rules="bindRules"
                :model="websiteBindForm"
            >
                <el-form-item label="网站名称">
                    <el-input
                        placeholder="请输入网站名称"
                        v-model="websiteBindForm.websiteName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="网站域名">
                    <el-input
                        placeholder="请输入网站域名"
                        v-model="websiteBindForm.domain"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addWebsiteDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addWebsiteBindV2()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 绑定企业对话框 -->
        <el-dialog
            title="绑定企业"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            @open="resetStatus"
        >
            <el-form ref="”bindForm“" :rules="bindRules" :model="bindForm">
                <el-form-item
                    label="填写要绑定的企业名称"
                    :label-width="bindingformLabelWidth"
                    prop="corpName"
                >
                    <el-input
                        v-model="bindForm.corpName"
                        placeholder="请输入企业名称"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="corpId"
                    label="在企业微信管理后台【我的企业】->【企业信息】中找到企业ID，填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="bindForm.corpId"
                        placeholder="请输入企业ID"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="agentId"
                    label="在企业微信管理后台【企业应用】->【自建应用】中点击要绑定的自建应用，点击自建应用可见到AgentID和Secret，分别填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="bindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                    <span
                        v-if="warningVisible"
                        style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        "
                        class="warning-text"
                        >*该应用已绑定，不能重复绑定</span
                    >
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="secret"
                >
                    <el-input
                        v-model="bindForm.secret"
                        placeholder="请输入Secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="getAuthUrl()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 钉钉群绑定钉钉及自建机器人 -->
        <!-- <el-dialog
            title="绑定钉钉及自建机器人"
            :visible.sync="dingdinggroupFormVisible"
            :close-on-click-modal="false"
            @open="resetStatus"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingGroupBindRules"
                :model="dingdingGroupBindForm"
            >
                <el-form-item
                    label="填写要绑定的企业名称"
                    :label-width="bindingformLabelWidth"
                    prop="corpName"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.corpName"
                        placeholder="请输入企业名称"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="corpId"
                    label="在钉钉管理后台【首页】中找到企业ID，填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.corpId"
                        placeholder="请输入企业ID"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="填写要绑定的机器人名称"
                    :label-width="bindingformLabelWidth"
                    prop="agentName"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.agentName"
                        placeholder="请输入机器人名称"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="agentId"
                    label="在钉钉管理后台【应用开发】->【机器人】中点击要绑定的自建机器人，点击自建机器人可见到AgentID、AppKey和AppSecret，分别填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                    <span
                        v-if="warningVisible"
                        style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        "
                        class="warning-text"
                        >*该应用已绑定，不能重复绑定</span
                    >
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="appKey"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.appKey"
                        placeholder="请输入AppKey"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="secret"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.secret"
                        placeholder="请输入Secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdinggroupFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="getAuthUrlDingdingGroup()"
                    >确 定</el-button
                >
            </div>
        </el-dialog> -->
        <!-- 钉钉绑定企业对话框 -->
        <el-dialog
            title="绑定企业"
            :visible.sync="dingdingDialogFormVisible"
            :close-on-click-modal="false"
            @open="resetStatus"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingBindRules"
                :model="dingdingBindForm"
            >
                <el-form-item
                    label="填写要绑定的企业名称"
                    :label-width="bindingformLabelWidth"
                    prop="corpName"
                >
                    <el-input
                        v-model="dingdingBindForm.corpName"
                        placeholder="请输入企业名称"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="corpId"
                    label="在钉钉管理后台【首页】中找到企业ID，填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingBindForm.corpId"
                        placeholder="请输入企业ID"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="填写要绑定的应用名称"
                    :label-width="bindingformLabelWidth"
                    prop="agentName"
                >
                    <el-input
                        v-model="dingdingBindForm.agentName"
                        placeholder="请输入应用名称"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="agentId"
                    label="在钉钉管理后台【应用开发】->【H5微应用】中点击要绑定的自建H5应用，点击自建应用可见到AgentID、AppKey和AppSecret，分别填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingBindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                    <span
                        v-if="warningVisible"
                        style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        "
                        class="warning-text"
                        >*该应用已绑定，不能重复绑定</span
                    >
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="appKey"
                >
                    <el-input
                        v-model="dingdingBindForm.appKey"
                        placeholder="请输入AppKey"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="secret"
                >
                    <el-input
                        v-model="dingdingBindForm.secret"
                        placeholder="请输入Secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdingDialogFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="getAuthUrlDingding()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 钉钉单聊绑定企业对话框 -->
        <el-dialog
            title="绑定企业"
            :visible.sync="dingdingSingDialogFormVisible"
            :close-on-click-modal="false"
            @open="resetStatus"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingBindRules"
                :model="dingdingSingBindForm"
            >
                <el-form-item
                    label="填写要绑定的企业名称"
                    :label-width="bindingformLabelWidth"
                    prop="corpName"
                >
                    <el-input
                        v-model="dingdingSingBindForm.corpName"
                        placeholder="请输入企业名称"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="corpId"
                    label="在钉钉管理后台【首页】中找到企业ID，填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingSingBindForm.corpId"
                        placeholder="请输入企业ID"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="填写要绑定的机器人应用名称"
                    :label-width="bindingformLabelWidth"
                    prop="agentName"
                >
                    <el-input
                        v-model="dingdingSingBindForm.agentName"
                        placeholder="请输入应用名称"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="agentId"
                    label="在钉钉管理后台【应用开发】->【企业内部开发】->【机器人】中点击要绑定的自建机器人应用，点击机器人应用可见到AgentID、AppKey和AppSecret，分别填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingSingBindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                    <span
                        v-if="warningVisible"
                        style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        "
                        class="warning-text"
                        >*该应用已绑定，不能重复绑定</span
                    >
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="appKey"
                >
                    <el-input
                        v-model="dingdingSingBindForm.appKey"
                        placeholder="请输入AppKey"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="secret"
                >
                    <el-input
                        v-model="dingdingSingBindForm.secret"
                        placeholder="请输入Secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdingSingDialogFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="getAuthUrlDingdingSing()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 查看已绑定钉钉单聊企业对话框 -->
        <el-dialog
            style="overflow-y: auto"
            title="已绑定企业"
            :close-on-click-modal="false"
            :visible.sync="dingdinSingInfoDialogVisible"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingBindRules"
                :model="dingdingSingBindForm"
            >
                <el-form-item label="企业名称">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业名称"
                        v-model="dingdingSingBindForm.corpName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="企业ID">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业ID"
                        v-model="dingdingSingBindForm.corpId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentID">
                    <el-input
                        disabled="disabled"
                        v-model="dingdingSingBindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentName">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入AgentName"
                        v-model="dingdingSingBindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppKey">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入AppKey"
                        v-model="dingdingSingBindForm.appKey"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Secret">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入Secret"
                        v-model="dingdingSingBindForm.secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdinSingInfoDialogVisible = false"
                    >关 闭</el-button
                >
            </div>
        </el-dialog>
        <!-- 云之家绑定 -->
        <el-dialog
            title="绑定应用"
            :visible.sync="yunzhijiaDialogFormVisible"
            :close-on-click-modal="false"
            @open="resetStatus"
            top="6vh"
        >
          <el-form
              ref="bindForm"
              :rules="yunzhijiaBindRules"
              :model="yunzhijiaBindForm"
          >
            <el-form-item
                label="填写要绑定的企业名称"
                :label-width="bindingformLabelWidth"
                prop="corpName"
            >
              <el-input
                  v-model.trim="yunzhijiaBindForm.corpName"
                  placeholder="请输入企业名称"
                  :autofocus="true"
              ></el-input>
            </el-form-item>
            <el-form-item
                prop="corpId"
                label="在云之家管理中心中找到注册EID，填入下方输入框中"
                :label-width="bindingformLabelWidth"
            >
              <el-input
                  v-model.trim="yunzhijiaBindForm.corpId"
                  placeholder="请输入注册EID"
              ></el-input>
            </el-form-item>
            <el-form-item
                label="填写要绑定的应用名称"
                :label-width="bindingformLabelWidth"
                prop="appName"
            >
              <el-input
                  v-model="yunzhijiaBindForm.appName"
                  placeholder="请输入应用名称"
                  :autofocus="true"
              ></el-input>
            </el-form-item>
            <el-form-item
                prop="appId"
                label="在云之家应用管理后台中，进入到要绑定的应用，在详情信息中可见App ID、App Secret和Sign Key，分别填入下方输入框中"
                :label-width="bindingformLabelWidth"
            >
              <el-input
                  v-model.trim="yunzhijiaBindForm.appId"
                  placeholder="请输入App ID"
              ></el-input>
              <span
                  v-if="warningVisible"
                  style="
                              color: #f56c6c;
                              display: block;
                              text-align: left;
                              font-size: 12px;
                              line-height: 16px;
                          "
                  class="warning-text"
              >*该应用已绑定，不能重复绑定</span
              >
            </el-form-item>
            <el-form-item
                :label-width="bindingformLabelWidth"
                prop="appSecret"
            >
              <el-input
                  v-model="yunzhijiaBindForm.appSecret"
                  placeholder="请输入App Secret"
              ></el-input>
            </el-form-item>
            <el-form-item
                :label-width="bindingformLabelWidth"
                prop="signKey"
            >
              <el-input
                  v-model.trim="yunzhijiaBindForm.signKey"
                  placeholder="请输入Sign Key"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="yunzhijiaDialogFormVisible = false"
            >取 消</el-button
            >
            <el-button type="primary" @click="getAuthUrlYunzhijia()"
            >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- 查看已绑定云之家企业对话框 -->
        <el-dialog
            style="overflow-y: auto"
            title="已绑定应用"
            :close-on-click-modal="false"
            :visible.sync="yunzhijiaInfoDialogVisible"
            top="6vh"
        >
          <el-form
              ref="bindForm"
              :rules="yunzhijiaBindRules"
              :model="yunzhijiaBindForm"
          >
            <el-form-item label="企业名称">
              <el-input
                  disabled="disabled"
                  placeholder="请输入企业名称"
                  v-model="yunzhijiaBindForm.corpName"
              ></el-input>
            </el-form-item>
            <el-form-item label="注册EID">
              <el-input
                  disabled="disabled"
                  placeholder="请输入注册EID"
                  v-model="yunzhijiaBindForm.corpId"
              ></el-input>
            </el-form-item>
            <el-form-item label="AppID">
              <el-input
                  disabled="disabled"
                  v-model="yunzhijiaBindForm.agentId"
                  placeholder="请输入AppID"
              ></el-input>
            </el-form-item>
            <el-form-item label="AgentName">
              <el-input
                  disabled="disabled"
                  placeholder="请输入AgentName"
                  v-model="yunzhijiaBindForm.agentName"
              ></el-input>
            </el-form-item>
            <el-form-item label="SignKey">
              <el-input
                  disabled="disabled"
                  placeholder="请输入SignKey"
                  v-model="yunzhijiaBindForm.appKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="AppSecret">
              <el-input
                  disabled="disabled"
                  placeholder="请输入AppSecret"
                  v-model="yunzhijiaBindForm.secret"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="yunzhijiaInfoDialogVisible = false"
            >关 闭</el-button
            >
          </div>
        </el-dialog>
        <!-- 绑定桌面网站面板 -->
        <div class="accesChannel">
            <el-button type="text" @click="addBindWebsite()" class="cardButton">
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <a class="url">
                        <img
                            src="../../assets/images/webchat.png"
                            class="image"
                        />
                        <p class="title">网站接入</p>
                        <p class="des">
                            可以在网站接入对话机器人，通过在线形式进行咨询
                        </p>
                    </a>
                </el-card>
            </el-button>
            <!--  绑定企业微信面板 -->
            <el-button type="text" @click="addBindCompany()" class="cardButton">
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img src="../../assets/images/qiyechat.png" class="image" />
                    <p class="title">企业微信</p>
                    <p class="des">
                        可以将机器人接入到企业微信自建应用，便于企业员工进行内部咨询服务
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定小程序面板 -->
            <el-button
                type="text"
                @click="addBindMiniProg()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        src="../../assets/images/xiaochengxu.jpeg"
                        class="image"
                    />
                    <p class="title">微信小程序</p>
                    <p class="des">
                        自动生成专属机器人的小程序码，可引导用户扫码进入专属小程序。
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定微信公众号面板 -->
            <el-button
                type="text"
                @click="addWechatMp()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        src="../../assets/images/Wechat_128px_1230528.png"
                        class="image"
                    />
                    <p class="title">微信公众号</p>
                    <p class="des">
                        可将机器人接入到微信订阅号或者服务号，进行智能交互。
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定钉钉面板 -->
            <el-button
                type="text"
                @click="addDingdingMp()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        style="border-radios"
                        src="../../assets/images/dingding.jpeg"
                        class="image"
                    />
                    <p class="title">钉钉</p>
                    <p class="des">
                        将机器人接入到钉钉自建H5应用中，打造钉钉数字员工。
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定APP面板 -->
            <!-- <el-button type="text" @click="addBindAPP()" class="cardButton">
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                  <img src="../../assets/images/resizeApi.png" class="image" />
                  <p class="title">APP </p>
                  <p class="des">可以将机器人接入到自建APP中，服务APP用户</p>
                </el-card>
            </el-button> -->
            <!-- 绑定微信客服面板 -->
            <!-- <el-button
                type="text"
                @click="addWxKfMp()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        src="../../assets/images/Wechat_128px_1230528.png"
                        class="image"
                    />
                    <p class="title">微信客服</p>
                    <p class="des">
                        可以在微信内、外各个场景中接入微信客服，做好客户咨询。
                    </p>
                </el-card>
            </el-button> -->
            <!-- 绑定钉钉群面板 -->
            <el-button
                type="text"
                @click="addDingdingGroup()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        style="border-radios"
                        src="../../assets/images/dingding.jpeg"
                        class="image"
                    />
                    <p class="title">钉钉群</p>
                    <p class="des">
                        将机器人接入到钉钉群聊中，打造钉钉智慧群。
                    </p>
                </el-card>
            </el-button>
             <!-- 绑定钉钉单聊面板 -->
            <el-button
                type="text"
                @click="addDingdingSing()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        style="border-radios"
                        src="../../assets/images/dingding.jpeg"
                        class="image"
                    />
                    <p class="title">钉钉单聊机器人</p>
                    <p class="des">
                        将机器人接入到钉钉消息列表中，打造专属钉钉数字员工。
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定飞书面板 -->
            <el-button
                type="text"
                @click="getFeishuList"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        style="border-radios"
                        src="../../assets/images/feishu.webp"
                        class="image"
                    />
                    <p class="title">飞书</p>
                    <p class="des">
                        将机器人接入到飞书，打造专属飞书数字员工。
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定云之家 -->
            <el-button
                type="text"
                @click="adYunzhijiaMp()"
                class="cardButton"
            >
              <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                <i class="iconfont guoran-yun" style="font-size: 60px;color: #3cbaff"></i>
                <p class="title">云之家</p>
                <p class="des">
                  将机器人接入到云之家自建应用，打造专属云数字员工。
                </p>
              </el-card>
            </el-button>
            <div>
                <!-- 桌面网站drawer -->
                <el-drawer
                    title="网站接入"
                    size="1200px"
                    :wrapperClosable="false"
                    :visible.sync="websitedrawer"
                >
                    <div class="website_drawer_content">
                        <div class="access-item" style="margin-top: 0">
                            <div class="name" style="margin-top: 30px">
                                <span class="stepNamber">1</span>
                            </div>
                            <div class="des">
                                <div class="binding">
                                    <p>添加网站信息并绑定机器人</p>
                                    <el-button
                                        type="primary"
                                        size="medium"
                                        class="bindingbutton"
                                        v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                        @click="addWebsiteDialogVisible = true"
                                        >添加</el-button
                                    >
                                </div>
                                <div class="bindingCompany">
                                    <div class="itemHeader">
                                        <div class="companyItem web-access">
                                            <div class="companyItemName">
                                                网站名称
                                            </div>
                                            <div class="companyappid">
                                                网站域名
                                            </div>
                                            <div class="companyItemTime">
                                                授权时间
                                            </div>
                                            <div class="user-login">
                                                用户登录
                                            </div>
                                            <div class="login-mode">
                                                登录方式
                                            </div>
                                            <div class="companyItemOperition">
                                                操作
                                            </div>
                                            <div class="bindBot">
                                                绑定机器人
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="itemContent"
                                        v-show="
                                            bindWebsiteList.length != 0 || true
                                        "
                                    >
                                        <div
                                            class="companyItem web-access"
                                            v-for="(
                                                item, index
                                            ) in bindWebsiteList"
                                            :key="index"
                                        >
                                            <div class="companyItemName">
                                                {{ item.agentName }}
                                            </div>
                                            <div class="companyappid">
                                                {{ item.agentId }}
                                            </div>
                                            <div class="companyItemTime">
                                                {{
                                                    new Date(
                                                        item.createTime
                                                    ).Format(
                                                        "yyyy-MM-dd hh:mm:ss"
                                                    )
                                                }}
                                            </div>
                                            <div class="user-login">
                                                <el-checkbox v-model="item.needLogin" @change="saveWebSet($event,item)"></el-checkbox>
                                            </div>
                                            <div class="login-mode login-mode-select">
                                                <img height="14px"  class="icon-img" v-if="item.iconType == 0" src="https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png" alt="" srcset="">
                                                <img height="15px" class="icon-img" v-if="item.iconType == 3" src="https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg" alt="" srcset="">
                                               <el-select
                                                    v-model="item.corpId"
                                                    size="small"
                                                    @change="saveWebSet($event,item)"
                                                    placeholder="请选择">
                                                    <el-option
                                                        v-for="item in loginModeOptions"
                                                        :key="item.corpId"
                                                        :label="item.corpName"
                                                        :value="item.corpId"
                                                    >
                                                        <img height="12px" v-if="item.corpType == 0" src="https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png" alt="" srcset="">
                                                        <img height="12px" v-if="item.corpType == 3" src="https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg" alt="" srcset="">
                                                        <span style="display: inline-block;margin-left: 6px;color: #8492a6; font-size: 13px;">{{ item.corpName }}</span>
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div class="companyItemOperition">
                                                <!-- 删除 -->
                                                <el-link
                                                    @click="
                                                        deleteWebsiteBind(
                                                            item.id
                                                        )
                                                    "
                                                    type="primary"
                                                    v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                    class="unbind"
                                                    >删除</el-link
                                                >
                                                <el-link
                                                    type="primary"
                                                    class="copy"
                                                    id="copy"
                                                    v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                    v-clipboard:copy="
                                                        item.websiteJsCode
                                                    "
                                                    v-clipboard:success="onCopy"
                                                    v-clipboard:error="onError"
                                                    >复制代码</el-link
                                                >
                                                <el-link
                                                    @click="botLinkSet(item)"
                                                    type="primary"
                                                    v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                    class="bot-link-set">
                                                    设置
                                                </el-link>
                                            </div>
                                            <div class="bindBot">
                                                <span v-for="(cell, cellIndex) in item.bindBot?item.bindBot.botInfos: []" :key="cell.botId" class="bindBotName"
                                                    > <span v-if="cellIndex != 0">、</span>{{cell.name}}</span
                                                >
                                                <span
                                                    style="color: #409eff"
                                                    v-if="item.bindBot && item.bindBot.count != 0 && RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                    class="editBindBot el-icon-s-tools"
                                                    @click="bindBotV2('website', 'edit', item)"
                                                ></span>
                                                <el-button
                                                    v-if="((item.bindBot == null) || (item.bindBot && item.bindBot.count == 0)) && RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                    size="mini"
                                                    type="primary"
                                                    @click="bindBotV2('website', 'add', item)"
                                                    >绑定机器人</el-button
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="itemContent"
                                        v-show="bindWebsiteList.length == 0"
                                    >
                                        <p class="null">没有添加网站</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">2</span>
                            </div>
                            <div class="des">
                                复制代码，将此代码嵌入到您的网站的{{
                                    htmlCode
                                }}标签之前，即可完成部署
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">3</span>
                            </div>
                            <div class="des">接入完成</div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 小程序drawer -->
                <el-drawer
                    title="接入微信小程序"
                    size="50%"
                    :wrapperClosable="false"
                    :visible.sync="xcxdrawer"
                >
                    <div class="qichat_drawer_content">
                        <ul class="mini-app">
                            <li class="mini-app-header">
                                <span class="mini-app-header-name">机器人名称</span>
                                <span class="mini-app-header-switch">是否启用微信小程序</span>
                                <span class="mini-app-header-code">小程序码</span>
                            </li>
                            <li v-for="(miniBot, miniBotIndex) in miniAppBots" :key="miniBotIndex" class="mini-app-body">
                                <span class="mini-app-header-name">{{miniBot.name}}</span>
                                <span class="mini-app-header-switch">
                                    <el-switch
                                        v-model="miniBot.setting.showAppletEntrance"
                                        active-color="#13ce66"
                                        inactive-color="#dcdfe6"
                                        :disabled="!RP_Visible('OPEN_ACCESS_WX_APPLET')"
                                        @change="updateBot($event,miniBot.id)"
                                    ></el-switch>
                                </span>
                                <span class="mini-app-header-code">
                                    <el-button :disabled="!miniBot.setting.showAppletEntrance" @click="miniAppCodeHandle(miniBotIndex, miniBot.id, 'download')" type="text">下载小程序码</el-button>
                                    <el-popover
                                      placement="right"
                                      width="300"
                                      trigger="click">
                                      <div>
                                          <el-image :src="miniBot.miniAppCodeUrl"></el-image>
                                      </div>
                                      <el-button :disabled="!miniBot.setting.showAppletEntrance" slot="reference" @click="miniAppCodeHandle(miniBotIndex, miniBot.id, 'preview')" type="text">预览</el-button>
                                    </el-popover>
                                    
                                </span>
                            </li>
                        </ul>
                    </div>
                </el-drawer>
                <!-- 企业微信drawer -->
                <el-drawer
                    title="接入企业微信"
                    :visible.sync="dialog"
                    ref="drawer"
                    size="70%"
                    :wrapperClosable="false"
                    custom-class="qichat"
                >
                    <div class="qichat_drawer_content">
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">1</span>
                            </div>
                            <div class="des">
                                管理员登陆【企业微信管理端后台】——>【应用管理】
                                ——>【自建应用】中创建一个自建应用
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">2</span>
                            </div>
                            <div class="des">
                                <p>
                                    复制下面的域名，在【企业微信管理后台】
                                    ——>【应用管理】——> 该应用管理页面 ——>
                                    【网页授权及JS-SDK】设置可信域名 ——>
                                    OAuth2.0可信域名
                                </p>
                                <div class="clicklink" v-if="RP_Visible('OPEN_ACCESS_EWX')">
                                    <p class="url" id="domain">
                                        {{ authDomain }}
                                    </p>
                                    <p
                                        class="copy"
                                        id="copyEnd"
                                        @click="copyDomain('domain', 'copyEnd')"
                                    >
                                        复制
                                    </p>
                                </div>
                                <div class="upload-content">
                                    <div class="upload-box">
                                        <div class="upload-des">
                                            设置可信域名时，点击【申请校验域名】，从企业微信下载文件并上传到此处
                                        </div>
                                        <el-upload
                                            class="upload-demo"
                                            :action="importFileUrl"
                                            name="file"
                                            :onError="uploadError"
                                            :onSuccess="uploadSuccess"
                                            :beforeUpload="beforeAvatarUpload"
                                            :show-file-list="false"
                                        >
                                            <el-button
                                                size="small"
                                                v-if="RP_Visible('OPEN_ACCESS_EWX')"
                                                type="primary"
                                                >上传归属校验文件</el-button
                                            >
                                        </el-upload>
                                    </div>
                                    <div class="warning-text">
                                        注意：先将文件上传，再返回企业微信后台中勾选【用于OAuth2.0回调的可信域名是否校验】
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span
                                    class="stepNamber"
                                    style="margin-top: 28px"
                                    >3</span
                                >
                            </div>
                            <div class="des">
                                <div class="binding">
                                    <p>绑定企业微信自建应用并绑定机器人</p>
                                    <el-button
                                        type="primary"
                                        size="medium"
                                        v-if="RP_Visible('OPEN_ACCESS_EWX')"
                                        class="bindingbutton"
                                        @click="dialogFormVisible = true"
                                        >绑定</el-button
                                    >
                                    <el-button
                                        type="primary"
                                        size="medium"
                                        v-if="RP_Visible('OPEN_ACCESS_EWX') && false"
                                        class="bindingbutton"
                                        @click="openTip"
                                        >绑定</el-button
                                    >
                                </div>
                                <div class="bindingCompany">
                                    <div class="itemHeader">
                                        <div class="companyItem">
                                            <div class="companyItemName">
                                                已绑定企业名称
                                            </div>
                                            <div class="companyappid">
                                                已绑定应用名称
                                            </div>
                                            <div class="companyItemTime">
                                                绑定时间
                                            </div>
                                            <div class="companyItemOperition">
                                                操作
                                            </div>
                                            <div class="bindBot">
                                                绑定机器人
                                            </div>
                                        </div>
                                    </div>
                                    <div class="itemContent">
                                        <div
                                            class="companyItem"
                                            v-for="(item, index) in bindList"
                                            :key="item.id"
                                        >
                                            <div class="companyItemName">
                                                {{ item.corpName }}
                                            </div>
                                            <div class="companyappid">
                                                {{ item.agentName }}
                                            </div>
                                            <div class="companyItemTime">
                                                {{
                                                    new Date(
                                                        item.updateTime
                                                    ).Format(
                                                        "yyyy-MM-dd hh:mm:ss"
                                                    )
                                                }}
                                            </div>
                                            <div class="companyItemOperition">
                                                <el-link
                                                    type="primary"
                                                    @click="showBindInfo(item)"
                                                    class="view"
                                                    >查看</el-link
                                                >
                                                <!-- 解绑 -->
                                                <el-link
                                                    @click="postUnbind(item.id)"
                                                    type="primary"
                                                    class="unbind"
                                                    v-if="RP_Visible('OPEN_ACCESS_EWX') && item.bindType == 1"
                                                    >解绑</el-link
                                                >
                                                <!-- 复制链接 -->
                                                <p
                                                    class="url"
                                                    style="display: none"
                                                    id="authUrlAddress"
                                                >
                                                    {{ item.authUrl }}
                                                </p>
                                                <el-link
                                                    type="primary"
                                                    class="copy"
                                                    id="copy"
                                                    v-if="RP_Visible('OPEN_ACCESS_EWX') && item.bindType == 1"
                                                    @click="
                                                        copy(
                                                            'authUrlAddress',
                                                            'copy',
                                                            item.authUrl
                                                        )
                                                    "
                                                    >复制链接</el-link
                                                >
                                            </div>
                                            <div class="bindBot">
                                                <span v-for="(cell, cellIndex) in item.bindBot?item.bindBot.botInfos: []" :key="cell.botId" class="bindBotName"
                                                    > <span v-if="cellIndex != 0">、</span>{{cell.name}}</span
                                                >
                                                <span
                                                    style="color: #409eff"
                                                    v-if="item.bindBot && item.bindBot.count != 0 && RP_Visible('OPEN_ACCESS_EWX')"
                                                    class="editBindBot el-icon-s-tools"
                                                    @click="bindBotV2('eWx', 'edit', item)"
                                                ></span>
                                                <el-button
                                                    v-if="((item.bindBot == null) || (item.bindBot && item.bindBot.count == 0)) && RP_Visible('OPEN_ACCESS_EWX')"
                                                    size="mini"
                                                    type="primary"
                                                    @click="bindBotV2('eWx', 'add', item)"
                                                    >绑定机器人</el-button
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">4</span>
                            </div>
                            <div class="des">
                                复制链接，在【企业微信管理后台】
                                ——>【应用管理】——> 该应用管理页面
                                ——>【工作台应用主页】填写复制的链接
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">5</span>
                            </div>
                            <div class="des">绑定完成</div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 飞书drawer -->
                <el-drawer
                    title="接入飞书"
                    :visible.sync="feishuDialog"
                    ref="drawer"
                    size="70%"
                    :wrapperClosable="false"
                    custom-class="qichat"
                >
                    <div class="qichat_drawer_content">
                        <div class="access-item">
                            <div class="des">
                                <!-- <div class="binding">
                                    <p>绑定机器人</p>
                                </div> -->
                                <div class="bindingCompany">
                                    <div class="itemHeader">
                                        <div class="companyItem">
                                            <div class="companyItemName">
                                                已绑定企业名称
                                            </div>
                                            <div class="companyappid">
                                                已绑定应用名称
                                            </div>
                                            <div class="companyItemTime">
                                                绑定时间
                                            </div>
                                            <div class="companyItemOperition">
                                                操作
                                            </div>
                                            <div class="bindBot">
                                                绑定机器人
                                            </div>
                                        </div>
                                    </div>
                                    <div class="itemContent">
                                        <div
                                            class="companyItem"
                                            v-for="(item, index) in bindFeishuList"
                                            :key="item.id + index"
                                        >
                                            <div class="companyItemName">
                                                {{ item.corpName }}
                                            </div>
                                            <div class="companyappid">
                                                {{ item.agentName }}
                                            </div>
                                            <div class="companyItemTime">
                                                {{
                                                    new Date(
                                                        item.updateTime
                                                    ).Format(
                                                        "yyyy-MM-dd hh:mm:ss"
                                                    )
                                                }}
                                            </div>
                                            <div class="companyItemOperition">
                                                <el-link
                                                    type="primary"
                                                    @click="showFeishuBindInfo(item)"
                                                    class="view"
                                                    >查看</el-link
                                                >
                                            </div>
                                            <div class="bindBot">
                                                <span v-for="(cell, cellIndex) in item.bindBot?item.bindBot.botInfos: []" :key="cell.botId" class="bindBotName"
                                                    > <span v-if="cellIndex != 0">、</span>{{cell.name}}</span
                                                >
                                                <span
                                                    style="color: #409eff"
                                                    v-if="item.bindBot && item.bindBot.count != 0"
                                                    class="editBindBot el-icon-s-tools"
                                                    @click="bindBotV2('eWx', 'edit', item)"
                                                ></span>
                                                <el-button
                                                    v-if="((item.bindBot == null) || (item.bindBot && item.bindBot.count == 0))"
                                                    size="mini"
                                                    type="primary"
                                                    @click="bindBotV2('eWx', 'add', item)"
                                                    >绑定机器人</el-button
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 钉钉drawer -->
                <el-drawer
                    title="接入钉钉"
                    :visible.sync="dingdingDialog"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                >
                    <div class="qichat_drawer_content">
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">1</span>
                            </div>
                            <div class="des">
                                钉钉应用管理员创建一个自建H5应用
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span
                                    class="stepNamber"
                                    style="margin-top: 28px"
                                    >2</span
                                >
                            </div>
                            <div class="des">
                                <div class="binding">
                                    <p style="margin-right: 16px">
                                        绑定钉钉及自建H5应用并绑定机器人
                                    </p>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        v-if="RP_Visible('OPEN_ACCESS_DING')"
                                        @click="bindDingding"
                                        >+绑定</el-button
                                    >
                                </div>
                                <div class="bindingCompany">
                                    <div class="itemHeader">
                                        <div class="companyItem">
                                            <div class="companyItemName">
                                                已绑定企业
                                            </div>
                                            <div class="companyappid">
                                                已绑定应用名称
                                            </div>
                                            <div class="companyItemTime">
                                                绑定时间
                                            </div>
                                            <div class="companyItemOperition">
                                                操作
                                            </div>
                                            <div class="bindBot">
                                                绑定机器人
                                            </div>
                                        </div>
                                    </div>
                                    <div class="itemContent">
                                        <div
                                            class="companyItem"
                                            v-for="item in dingdingH5AppList"
                                            :key="item.id"
                                        >
                                            <div class="companyItemName">
                                                {{ item.corpName }}
                                            </div>
                                            <div class="companyappid">
                                                {{ item.agentName }}
                                            </div>
                                            <div class="companyItemTime">
                                                {{
                                                    new Date(
                                                        item.updateTime
                                                    ).Format(
                                                        "yyyy-MM-dd hh:mm:ss"
                                                    )
                                                }}
                                            </div>
                                            <div class="companyItemOperition">
                                                <el-link
                                                    type="primary"
                                                    @click="
                                                        showDingdingBindInfo(
                                                            item
                                                        )
                                                    "
                                                    class="view"
                                                    >查看</el-link
                                                >
                                                <!-- 解绑 -->
                                                <el-link
                                                    @click="
                                                        postDingdingUnbind(
                                                            item.id
                                                        )
                                                    "
                                                    v-if="RP_Visible('OPEN_ACCESS_DING')"
                                                    type="primary"
                                                    class="unbind"
                                                    >解绑</el-link
                                                >
                                                <!-- 复制链接 -->
                                                <p
                                                    class="url"
                                                    style="display: none"
                                                    id="authUrlAddress"
                                                >
                                                    {{ item.authUrl }}
                                                </p>
                                                <el-link
                                                    type="primary"
                                                    class="copy"
                                                    v-if="RP_Visible('OPEN_ACCESS_DING')"
                                                    id="copy"
                                                    @click="
                                                        copyDingdingUrl(
                                                            'authUrlAddress',
                                                            'copy',
                                                            item.authUrl
                                                        )
                                                    "
                                                    >复制链接</el-link
                                                >
                                            </div>
                                            <div class="bindBot">
                                                <span v-for="(cell, cellIndex) in item.bindBot?item.bindBot.botInfos: []" :key="cell.botId" class="bindBotName"
                                                    > <span v-if="cellIndex != 0">、</span>{{cell.name}}</span
                                                >
                                                <span
                                                    style="color: #409eff"
                                                    v-if="(item.bindBot && item.bindBot.count != 0) && RP_Visible('OPEN_ACCESS_DING')"
                                                    class="editBindBot el-icon-s-tools"
                                                    @click="bindBotV2('eWx', 'edit', item)"
                                                ></span>
                                                <el-button
                                                    v-if="((item.bindBot == null) || (item.bindBot && item.bindBot.count == 0)) && RP_Visible('OPEN_ACCESS_DING')"
                                                    size="mini"
                                                    type="primary"
                                                    @click="bindBotV2('eWx', 'add', item)"
                                                    >绑定机器人</el-button
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="itemContent"
                                        v-show="
                                            dingdingH5AppList == null ||
                                            dingdingH5AppList.length == 0
                                        "
                                    >
                                        <br />
                                        <p
                                            class="null"
                                            style="text-align: center"
                                        >
                                            暂 无
                                        </p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">3</span>
                            </div>
                            <div class="des">
                                复制链接，在开发管理绑定【应用首页地址】
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">4</span>
                            </div>
                            <div class="des">
                                <p>
                                    在开发管理中绑定服务器出口IP，出口IP地址如下：
                                </p>
                                <div class="clicklink">
                                    <p class="url" id="dingtalkWhite">
                                        {{ dingtalkWhite }}
                                    </p>
                                    <p
                                        class="copy"
                                        id="copyEndIp"
                                        v-if="RP_Visible('OPEN_ACCESS_DING')"
                                        @click="
                                            copyDomain(
                                                'dingtalkWhite',
                                                'copyEndIp'
                                            )
                                        "
                                    >
                                        复制
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">5</span>
                            </div>
                            <div class="des">
                                <p>
                                    在开发管理中绑定管理后台地址，管理后台地址如下：
                                </p>
                                <div class="clicklink">
                                    <p class="url" id="domainDing">
                                        {{ openDomain }}
                                    </p>
                                    <p
                                        class="copy"
                                        id="copyEndDing"
                                        v-if="RP_Visible('OPEN_ACCESS_DING')"
                                        @click="copyDomain('domainDing', 'copyEndDing')"
                                    >
                                        复制
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">6</span>
                            </div>
                            <div class="des">
                                <p>
                                    在权限管理中添加接口权限，如下图所示（注：不添加接口权限会导致机器人获取不到机器人信息）：
                                </p>
                                <div class="clicklink">
                                    <p>
                                        <img
                                            src="./../../assets/images/WechatIMG162.jpeg"
                                            alt=""
                                            srcset=""
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">7</span>
                            </div>
                            <div class="des">绑定完成</div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 钉钉单聊drawer -->
                <el-drawer
                    title="接入钉钉单聊"
                    :visible.sync="dingdingSingDialog"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                >
                    <div class="qichat_drawer_content">
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">1</span>
                            </div>
                            <div class="des">
                                钉钉应用管理员创建一个自建机器人应用
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span
                                    class="stepNamber"
                                    style="margin-top: 28px"
                                    >2</span
                                >
                            </div>
                            <div class="des">
                                <div class="binding">
                                    <p style="margin-right: 16px">
                                        绑定钉钉及自建机器人应用
                                    </p>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="bindDingdingSing"
                                        >+绑定</el-button
                                    >
                                </div>
                                <div class="bindingCompany">
                                    <div class="itemHeader">
                                        <div class="companyItem">
                                            <div class="companyItemName">
                                                已绑定企业
                                            </div>
                                            <div class="companyappid">
                                                已绑定钉钉机器人
                                            </div>
                                            <div class="companyItemTime">
                                                绑定时间
                                            </div>
                                            <div class="companyItemOperition">
                                                操作
                                            </div>
                                            <div class="bindBot">
                                                绑定机器人
                                            </div>
                                        </div>
                                    </div>
                                    <div class="itemContent">
                                        <div
                                            class="companyItem"
                                            v-for="item in dingdingSingH5AppList"
                                            :key="item.id"
                                        >
                                            <div class="companyItemName">
                                                {{ item.corpName }}
                                            </div>
                                            <div class="companyappid">
                                                {{ item.agentName }}
                                            </div>
                                            <div class="companyItemTime">
                                                {{
                                                    new Date(
                                                        item.updateTime
                                                    ).Format(
                                                        "yyyy-MM-dd hh:mm:ss"
                                                    )
                                                }}
                                            </div>
                                            <div class="companyItemOperition">
                                                <el-link
                                                    type="primary"
                                                    @click="
                                                        showDingdingSingBindInfo(
                                                            item
                                                        )
                                                    "
                                                    class="view"
                                                    >查看</el-link
                                                >
                                                <!-- 解绑 -->
                                                <el-link
                                                    @click="
                                                        postDingdingSingUnbind(
                                                            item.id
                                                        )
                                                    "
                                                    type="primary"
                                                    class="unbind"
                                                    >解绑</el-link
                                                >
                                                <!-- 复制链接 -->
                                                <p
                                                    class="url"
                                                    style="display: none"
                                                    id="authUrlAddress"
                                                >
                                                    {{ item.authUrl }}
                                                </p>
                                                <el-link
                                                    type="primary"
                                                    class="copy"
                                                    id="copy"
                                                    @click="
                                                        copyDingdingUrl(
                                                            'authUrlAddress',
                                                            'copy',
                                                            item.authUrl
                                                        )
                                                    "
                                                    >复制链接</el-link
                                                >
                                            </div>
                                            <div class="bindBot">
                                                <span v-for="(cell, cellIndex) in item.bindBot?item.bindBot.botInfos: []" :key="cell.botId" class="bindBotName"
                                                    > <span v-if="cellIndex != 0">、</span>{{cell.name}}</span
                                                >
                                                <span
                                                    style="color: #409eff"
                                                    v-if="(item.bindBot && item.bindBot.count != 0)"
                                                    class="editBindBot el-icon-s-tools"
                                                    @click="bindBotV2('eWx', 'edit', item)"
                                                ></span>
                                                <el-button
                                                    v-if="((item.bindBot == null) || (item.bindBot && item.bindBot.count == 0))"
                                                    size="mini"
                                                    type="primary"
                                                    @click="bindBotV2('eWx', 'add', item)"
                                                    >绑定机器人</el-button
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="itemContent"
                                        v-show="
                                            dingdingSingH5AppList == null ||
                                            dingdingSingH5AppList.length == 0
                                        "
                                    >
                                        <br />
                                        <p
                                            class="null"
                                            style="text-align: center"
                                        >
                                            暂 无
                                        </p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">3</span>
                            </div>
                            <div class="des">
                                复制应用链接，在开发管理绑定【消息接收地址】
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">4</span>
                            </div>
                            <div class="des">
                                <p>
                                    在开发管理中绑定服务器出口IP，出口IP地址如下：
                                </p>
                                <div class="clicklink">
                                    <p class="url" id="dingtalkWhite">
                                        {{ dingtalkWhite }}
                                    </p>
                                    <p
                                        class="copy"
                                        id="copyEndIp"
                                        v-if="RP_Visible('OPEN_ACCESS_DING')"
                                        @click="
                                            copyDomain(
                                                'dingtalkWhite',
                                                'copyEndIp'
                                            )
                                        "
                                    >
                                        复制
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">5</span>
                            </div>
                            <div class="des">
                                <p>
                                    在权限管理中添加接口权限，如下图所示（注：不添加接口权限会导致机器人不可正常使用）
                                </p>
                                <div class="clicklink">
                                    <p>
                                        <img
                                            src="./../../assets/images/dingdingSing6.png"
                                            width="100%"
                                            height="auto"
                                            alt=""
                                            srcset=""
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">6</span>
                            </div>
                            <div class="des">绑定完成</div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 钉钉群drawer -->
                <el-drawer
                    title="接入钉钉群"
                    :visible.sync="dingdingGroupDialog"
                    ref="dingdingGroupDialog"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                >
                    <dingding-group
                        ref="dingdinggroup"
                        @bindBotV2="bindBotV2"
                        @editDingdingGroupList="editDingdingGroupList"
                    ></dingding-group>
                </el-drawer>
                <el-drawer
                    title="已绑定钉钉群管理"
                    :visible.sync="bindedDingdingGroupDialog"
                    ref="bindedDingdingGroupDialog"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                    @close="closeDdgList"
                >
                    <binded-dingdinggroup
                        ref="bindeddingdinggroup"
                        @bindBotV2="bindBotV2"
                        @getGroupTemplateList="getGroupTemplateList"
                        :dingdingGroupInfoStr="dingdingGroupInfoStr"
                        :tamplateId="dingdingGroupTamplateId"
                        :replyVisible="dingdingGroupReplyVisible"
                    ></binded-dingdinggroup>
                </el-drawer>
                <!-- 微信公众号drawer -->
                <el-drawer
                    title="接入微信订阅号/服务号"
                    :visible.sync="wechatmpdialog"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                >
                    <div class="qichat_drawer_content">
                        <div class="access-item" style="margin-top: 0px">
                            <div class="name">
                                <span
                                    class="stepNamber"
                                    style="margin-top: 28px"
                                    >1</span
                                >
                            </div>
                            <div class="des">
                                <div
                                    class="binding"
                                    data-authtype="1"
                                    ref="authTypeInfo"
                                >
                                    <p>授权微信公众号并绑定机器人</p>
                                    <el-button
                                        type="primary"
                                        size="medium"
                                        v-if="RP_Visible('OPEN_ACCESS_PWX')"
                                        class="bindingbutton"
                                        @click="bindWechatByThirdAuth"
                                        >授权微信公众号</el-button
                                    >
                                </div>
                                <p
                                    style="
                                        color: #d9001b;
                                        font-size: 12px;
                                        margin-bottom: 5px;
                                    "
                                >
                                    注意：您的公众号必须是认证的微信订阅号或服务号，一个微信公众号只能绑定一个机器人，绑定第二个机器人时之前绑定的机器人会自动解绑
                                </p>
                                <div class="bindingCompany">
                                    <div class="itemHeader">
                                        <div class="companyItem">
                                            <div class="companyItemName">
                                                已授权公众号
                                            </div>
                                            <div class="companyItemTime">
                                                授权时间
                                            </div>
                                            <!-- <div class="companyappid">授权方式</div> -->
                                            <div class="companyItemOperition">
                                                操作
                                            </div>
                                            <div class="companyItemOperition">
                                                用户登录
                                            </div>
                                            <div class="bindBot">
                                                绑定机器人
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="itemContent"
                                        v-show="
                                            wechatMpbindList == null ||
                                            wechatMpbindList.length == 0
                                        "
                                    >
                                        <p
                                            class="null"
                                            style="text-align: center"
                                        >
                                            暂无
                                        </p>
                                    </div>
                                    <div
                                        class="itemContent"
                                        v-show="
                                            wechatMpbindList != null &&
                                            wechatMpbindList.length > 0
                                        "
                                    >
                                        <div
                                            class="companyItem"
                                            v-for="(
                                                item, index
                                            ) in wechatMpbindList"
                                            :key="item.id"
                                        >
                                            <div class="companyItemName">
                                                {{ item.agentName }}
                                            </div>
                                            <div class="companyItemTime">
                                                {{
                                                    new Date(
                                                        item.updateTime
                                                    ).Format(
                                                        "yyyy-MM-dd hh:mm:ss"
                                                    )
                                                }}
                                            </div>
                                            <!-- <div class="companyappid">{{item.bindType==0?'第三方':'开发者'}}</div> -->
                                            <div class="companyItemOperition">
                                                <!-- <el-link type="primary" @click="showWechatMpBindInfo(item)" class="view">查看</el-link> -->
                                                <!-- 解绑 -->
                                                <el-link
                                                    @click="
                                                        deleteWechatMpBind(
                                                            item.id
                                                        )
                                                    "
                                                    type="primary"
                                                    class="unbind"
                                                    v-if="RP_Visible('OPEN_ACCESS_PWX')"
                                                    >解绑</el-link
                                                >
                                                <!-- 复制链接 -->
                                                <p
                                                    class="url"
                                                    style="display: none"
                                                    id="authUrlAddress"
                                                >
                                                    {{ item.authUrl }}
                                                </p>
                                                <el-link
                                                    type="primary"
                                                    class="copy"
                                                    v-if="RP_Visible('OPEN_ACCESS_PWX')"
                                                    id="copy"
                                                    @click="
                                                        copy(
                                                            'authUrlAddress',
                                                            'copy',
                                                            item.authUrl
                                                        )
                                                    "
                                                    >复制链接</el-link
                                                >
                                            </div>
                                            <div class="companyItemOperition">
                                                <el-checkbox
                                                    v-if="item.serviceType != 0"
                                                    :disabled="!RP_Visible('OPEN_ACCESS_PWX')"
                                                    @change="
                                                        changeAllowPushMsg(
                                                            $event,
                                                            item
                                                        )
                                                    "
                                                    v-model="item.allowPushMsg"
                                                ></el-checkbox>
                                                <div
                                                    @click="showtips"
                                                    v-if="item.serviceType == 0"
                                                    style="
                                                        margin: 0 auto;
                                                        width: 12px;
                                                        height: 12px;
                                                        border: 1px solid
                                                            #dcdfe6;
                                                    "
                                                ></div>
                                            </div>
                                            <div class="bindBot">
                                                <span v-for="(cell, cellIndex) in item.bindBot?item.bindBot.botInfos: []" :key="cell.botId" class="bindBotName"
                                                    > <span v-if="cellIndex != 0">、</span>{{cell.name}}</span
                                                >
                                                <span
                                                    style="color: #409eff"
                                                    v-if="item.bindBot && item.bindBot.count != 0 && RP_Visible('OPEN_ACCESS_PWX')"
                                                    class="editBindBot el-icon-s-tools"
                                                    @click="bindBotV2('eWx', 'edit', item)"
                                                ></span>
                                                <el-button
                                                    v-if="((item.bindBot == null) || (item.bindBot && item.bindBot.count == 0)) && RP_Visible('OPEN_ACCESS_PWX')"
                                                    size="mini"
                                                    type="primary"
                                                    @click="bindBotV2('eWx', 'add', item)"
                                                    >绑定机器人</el-button
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">2</span>
                            </div>
                            <div class="des">
                                复制链接，在公众号自定义菜单中设置跳转网页或设置自动回复内容为该链接
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">3</span>
                            </div>
                            <div class="des">接入完成</div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 接入微信客服drawer -->
                <el-drawer
                    title="接入微信客服"
                    :visible.sync="wxKfDialog"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                >
                    <WxKf ref="wxkf" @bindBotV2Children="bindBotV2Children"/>
                </el-drawer>
                <!-- 云之家drawer -->
                <el-drawer
                    title="接入云之家"
                    :visible.sync="yunzhijiaDrawer"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                >
                  <div class="qichat_drawer_content">
                    <div class="access-item">
                      <div class="name">
                        <span class="stepNamber">1</span>
                      </div>
                      <div class="des">
                        云之家管理员添加一个应用
                      </div>
                    </div>
                    <div class="access-item">
                      <div class="name">
                        <span
                            class="stepNamber"
                            style="margin-top: 28px"
                        >2</span
                        >
                      </div>
                      <div class="des">
                        <div class="binding">
                          <p style="margin-right: 16px">
                            绑定云之家应用并绑定机器人
                          </p>
                          <el-button
                              type="primary"
                              size="mini"
                              @click="bindYunzhijia"
                          >+绑定</el-button
                          >
                        </div>
                        <div class="bindingCompany">
                          <div class="itemHeader">
                            <div class="companyItem">
                              <div class="companyItemName">
                                已绑定企业
                              </div>
                              <div class="companyappid">
                                已绑定应用名称
                              </div>
                              <div class="companyItemTime">
                                绑定时间
                              </div>
                              <div class="companyItemOperition">
                                操作
                              </div>
                              <div class="bindBot">
                                绑定机器人
                              </div>
                            </div>
                          </div>
                          <div class="itemContent">
                            <div
                                class="companyItem"
                                v-for="item in yunzhijiaList"
                                :key="item.id"
                            >
                              <div class="companyItemName">
                                {{ item.corpName }}
                              </div>
                              <div class="companyappid">
                                {{ item.agentName }}
                              </div>
                              <div class="companyItemTime">
                                {{
                                  new Date(
                                      item.updateTime
                                  ).Format(
                                      "yyyy-MM-dd hh:mm:ss"
                                  )
                                }}
                              </div>
                              <div class="companyItemOperition">
                                <el-link
                                    type="primary"
                                    @click="showYunzhijiaBindInfo(item)"
                                    class="view"
                                >查看</el-link
                                >
                                <!-- 解绑 -->
                                <el-link
                                    @click="postDingdingUnbind(item.id,'yunzhijia')"
                                    type="primary"
                                    class="unbind"
                                >解绑</el-link
                                >
                                <!-- 复制链接 -->
                                <p
                                    class="url"
                                    style="display: none"
                                    id="authUrlAddress"
                                >
                                  {{ item.authUrl }}
                                </p>
                                <el-link
                                    type="primary"
                                    class="copy"
                                    id="copy"
                                    @click="copyDingdingUrl('authUrlAddress','copy',item.authUrl)"
                                >复制链接</el-link
                                >
                              </div>
                              <div class="bindBot">
                                <span v-for="(cell, cellIndex) in item.bindBot?item.bindBot.botInfos: []" :key="cell.botId" class="bindBotName"
                                > <span v-if="cellIndex != 0">、</span>{{cell.name}}</span
                                >
                                <span
                                    style="color: #409eff"
                                    v-if="(item.bindBot && item.bindBot.count != 0) && RP_Visible('OPEN_ACCESS_DING')"
                                    class="editBindBot el-icon-s-tools"
                                    @click="bindBotV2('eWx', 'edit', item)"
                                ></span>
                                <el-button
                                    v-if="((item.bindBot == null) || (item.bindBot && item.bindBot.count == 0)) && RP_Visible('OPEN_ACCESS_DING')"
                                    size="mini"
                                    type="primary"
                                    @click="bindBotV2('eWx', 'add', item)"
                                >绑定机器人</el-button
                                >
                              </div>
                            </div>
                          </div>
                          <div
                              class="itemContent"
                              v-show="yunzhijiaList == null || yunzhijiaList.length == 0"
                          >
                            <br />
                            <p
                                class="null"
                                style="text-align: center"
                            >
                              暂 无
                            </p>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="access-item">
                      <div class="name">
                        <span class="stepNamber">3</span>
                      </div>
                      <div class="des">
                        复制链接，在开发管理中开启轻应用，将链接配置到移动端、网页端、桌面端
                      </div>
                    </div>
                    <div class="access-item">
                      <div class="name">
                        <span class="stepNamber">4</span>
                      </div>
                      <div class="des">配置好链接后，提交审核，并在云之家业务中心中审核自建应用</div>
                    </div>
                  </div>
                </el-drawer>
                <!-- APPdrawer -->
                <el-drawer :wrapperClosable="false" title="接入APP" size="50%" :visible.sync="APPdrawer">
                    <div class="qichat_drawer_content">
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">1</span>
                            </div>
                            <div class="des">是否启用APP接入</div>
                        </div>
                    </div>
                </el-drawer>
                <el-dialog
                    id="bindBotDialog"
                    title="绑定机器人"
                    :visible.sync="bindBotDialogVisible"
                    :close-on-click-modal="false"
                    @closed="closeBindBotDialog"
                    :width="(bindBotsAppType != 'website' && bindBotsAppType != 'wechatGZ' && bindBotsAppType != 'wxServ')? '1100px':'500px'"
                >
                    <div class="bindBotContent">
                        <div class="bind-bot-left">
                            <span class="bind-bot-title">未绑定机器人</span>
                            <div class="select-bot-list">
                                <el-checkbox-group
                                    v-model="checkedBots"
                                    @change="handleCheckedBotsChange"
                                >
                                    <el-checkbox
                                        v-for="(bot, botIndex) in noBindBots"
                                        v-show="!bot.isChecked"
                                        :label="bot.id"
                                        :key="botIndex"
                                        >{{ bot.name }}</el-checkbox
                                    >
                                </el-checkbox-group>
                            </div>
                        </div>
                        <div class="bind-bot-right">
                            <div class="bind-bot-title">
                                <span class="bind-bot-title-l"
                                    >已绑定机器人</span
                                >
                                <span v-if="bindBotsAppType != 'website' && bindBotsAppType != 'wechatGZ' && bindBotsAppType != 'wxServ'" class="bind-bot-title-r">可咨询范围</span>
                            </div>
                            <div class="bind-bot-list">
                                <div
                                    v-for="(item, index) in bindBots"
                                    :key="index"
                                    class="bind-bot-cell"
                                >
                                    <div class="bind-bot-base-info">
                                        <span class="bind-bot-name">{{
                                            item.name
                                        }}</span>
                                        <span
                                            @click="deleteBindBotToNo(index)"
                                            class="el-icon-close delete-bot-icon"
                                        ></span>
                                    </div>

                                    <div v-if="bindBotsAppType != 'website' && bindBotsAppType != 'wechatGZ' && bindBotsAppType != 'wxServ'" class="response-range">
                                        <el-radio-group @change="changeItemScope" v-model="item.scope">
                                            <el-radio :label="0"
                                                >所有人</el-radio
                                            >
                                            <el-radio :label="1"
                                                > <span v-if="!isFwh">按组织架构选择</span> <span v-if="isFwh">按上下游选择</span> </el-radio
                                            >
                                        </el-radio-group>
                                        <div
                                            v-if="item.scope == 1"
                                            class="bind-bot-self-tree-box"
                                        >
                                            <div
                                                @click.stop="
                                                    setActiveTreeIndex(index)
                                                "
                                                :class="['selected-box', activeTreeIndex == index?'active-bg':'pry-bg']"
                                            >
                                                <div class="tag-box">
                                                    <span class="placeholder" v-show="item.items == null || item.items.length == 0">{{isFwh?'请点击选择上下游':'请点击选择组织架构'}}</span>
                                                    <el-tag
                                                        v-for="(
                                                            tagCell, tagCellIndex
                                                        ) in item.items"
                                                        :key="tagCellIndex"
                                                        size="mini"
                                                        @close="
                                                            handleCloseOrganization(
                                                                index,
                                                                tagCellIndex
                                                            )
                                                        "
                                                        closable
                                                    >
                                                      <span v-if="isOpenData(tagCell.name)">
                                                        <open-data :type="tagCell.type == 1? 'departmentName': 'userName'" :openid="tagCell.name"></open-data>
                                                      </span>
                                                      <span v-else>{{tagCell.name }}</span>
                                                    </el-tag
                                                >
                                                </div>
                                                <!-- <div class="handle-teg-box">
                                                    <span v-show="activeTreeIndex == index" class="el-icon-arrow-up"></span>
                                                    <span v-show="activeTreeIndex != index" class="el-icon-arrow-down"></span>
                                                </div> -->
                                                
                                            </div>
                                            <div
                                                v-if="
                                                    activeTreeIndex == index && isFwh
                                                "
                                                @click.stop="selectOrg"
                                                class="bind-bot-self-tree"
                                            >
                                              <div v-for="(item,orgindex) in orgTree" :key="orgindex">
                                                <el-tree
                                                    :data="[item]"
                                                    node-key="id"
                                                    :default-expand-all="defaultExpandAll"
                                                    :props="defaultProps"
                                                    :expand-on-click-node="
                                                        false
                                                    "
                                                >
                                                    <span
                                                        class="custom-tree-node"
                                                        slot-scope="{
                                                            node,
                                                            data,
                                                        }"
                                                    >
                                                      <span v-if="item.originData&&
                                                      item.originData.wechatBotDTO&&
                                                      item.originData.wechatBotDTO.bindType===0&&
                                                      item.originData.wechatBotDTO.wechatType===0">
                                                        <open-data :type="'departmentName'" :openid="data.label"></open-data>
                                                      </span>
                                                      <span v-else>{{node.label }}</span>
                                                      <span>
                                                            <el-checkbox
                                                                @change="
                                                                    changeCheckedOrg(
                                                                        node,
                                                                        data,
                                                                        index
                                                                    )
                                                                "
                                                                v-model="
                                                                    data.checked
                                                                "
                                                            ></el-checkbox>
                                                        </span>
                                                    </span>
                                                </el-tree>
                                              </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="cancelNewBindBot">取 消</el-button>
                        <el-button type="primary" @click="submitNewBindBot"
                            >确 定</el-button
                        >
                    </span>
                </el-dialog>
                <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree">
                    <div slot="popup-name">选择可咨询范围</div>
                    <div slot="popup-con" class="member-contanier">
                        <staff-customer :clientValue="checkClientId"
                                        :selectIdsP="selectIds"
                                        :activeBindBotsItems="activeBindBotsItems"
                                        @onNodeClick="onNodeClick"
                                        :activeCorpId="activeCorpId"
                                        :isMultiple="true"
                                        :hasClientNext="hasClientNext"
                                        @loadMore="lookMore"
                                        ref="staffCustomer"
                                        :isShowUpDown="true"
                        ></staff-customer>
                    </div>
                    <div slot="dialog-footer">
                        <el-button style="width: 100px" @click="dialogMemberTree=false" plain size="small">取 消</el-button>
                        <el-button style="width: 100px" type="primary"  @click="preserStaff" size="small">确 定</el-button>
                    </div>
                </popup>
            </div>
        </div>
    </div>
</template>
<script>
import WxKf from "./component/BotlinkWXkefu.vue"
import DingdingGroup from './component/DingdingGroup.vue';
import BindedDingdinggroup from "./component/DingdinggroupList.vue";
import OpenData from "@/components/openData";
import popup from "../../components/popupNew.vue";

import StaffCustomer from './component/SelectStaff.vue';

export default {
    components:{OpenData, WxKf, DingdingGroup, BindedDingdinggroup, StaffCustomer, popup },
    data() {
        return {
            // 选择可咨询范围
            dialogMemberTree: false,
            checkClientId: [],
            selectIds: [], // 已选中的组织架构id
            activeBindBotsItems: [],
            activeCorpId: "",

            isFwh: false, // 当前是否在绑定微信公众号
            bindBotDialogVisible: false,
            checkedBots: [],
            noBindBots: [],
            bindActiveAppItem: {},
            bindBots: [],
            activeTreeIndex: null,
            defaultCheckedKeys: [5],
            orgTree: [],
            defaultExpandAll: false,
            bindBotsAppType: "",
            defaultProps: {
                children: "children",
                label: "name",
            },
            xcxdrawer: false,
            miniAppBots: [],
            isOpenMiniprogram: false,
            miniProgramUrl: "",
            warningVisible: false,
            activePageInfo: {
                // 当前页跳转信息
                code: "",
                state: "",
            },
            websitedrawer: false,
            htmlCode: "</body>",
            bindWebsiteList: [],
            addWebsiteDialogVisible: false,
            websiteBindForm: { websiteName: "", domain: "", botId: "" },
            table: false,
            dialog: false,
            loading: false,
            drawer: false,
            formLabelWidth: "80px",
            bindingformLabelWidth: "",
            timer: null,
            dialogFormVisible: false,
            authDomain: "",
            openDomain: "",
            authUrl: "",
            dialogVisible: false,
            bindList: [],
            bindForm: {
                agentId: "",
                bindType: "",
                corpId: "",
                corpName: "",
                secret: "",
            },
            bindRules: {
                corpName: [
                    {
                        required: true,
                        message: "请输入企业名称",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                corpId: [
                    {
                        required: true,
                        message: "请输入企业ID",
                        trigger: "blur",
                    },
                    { max: 32, message: "长度最长为32个字符", trigger: "blur" },
                ],
                agentId: [
                    {
                        required: true,
                        message: "请输入AgentID",
                        trigger: "blur",
                    },
                    { max: 32, message: "长度最长为64个字符", trigger: "blur" },
                ],
                secret: [
                    {
                        required: true,
                        message: "请输入secret",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
            },
            infoDialogVisible: false,
            fileList: [],
            importFileUrl: location.origin + "/api//bind/ewechat/uploadFile",
            settingList: {
                id: "",
                //name: "第1个机器",
                setting: {
                    showAppletEntrance: true,
                },
            },
            APPdrawer: false,
            wechatmpdialog: false,
            wechatMpbindList: [],
            wechatMpBindForm: {},
            wechatMpShowDialogVisible: false,
            wechatMpAuthDialog: false,
            developerDialog: false,
            publicThirdAuthType: "1",
            // 微信客服
            wxKfDialog: false,
            wxKfAppList: [],
            // 钉钉
            dingdingDialog: false,
            dingdingH5AppList: [],
            dingdingDialogFormVisible: false,
            dingdingBindForm: {
                agentId: "",
                corpId: "",
                corpName: "",
                secret: "",
                appKey: "",
                agentName: "",
            },
            dingdingBindRules: {
                agentName: [
                    {
                        required: true,
                        message: "请输入应用名称",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                corpName: [
                    {
                        required: true,
                        message: "请输入企业名称",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                corpId: [
                    {
                        required: true,
                        message: "请输入企业ID",
                        trigger: "blur",
                    },
                    { max: 36, message: "长度最长为36个字符", trigger: "blur" },
                ],
                agentId: [
                    {
                        required: true,
                        message: "请输入AgentID",
                        trigger: "blur",
                    },
                    { max: 32, message: "长度最长为64个字符", trigger: "blur" },
                ],
                secret: [
                    {
                        required: true,
                        message: "请输入secret",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                appKey: [
                    {
                        required: true,
                        message: "请输入appKey",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
            },
            dingdinInfoDialogVisible: false,
            dingtalkWhite: "",
            // 钉钉单聊
            dingdingSingDialog: false,
            dingdingSingH5AppList: [],
            dingdingSingDialogFormVisible: false,
            dingdingSingBindForm: {
                agentId: "",
                corpId: "",
                corpName: "",
                secret: "",
                appKey: "",
                agentName: "",
            },
            dingdinSingInfoDialogVisible: false,
            dingSingtalkWhite: "",
            // 钉钉群
            dingdingGroupDialog: false,
            bindedDingdingGroupDialog: false,
            dingdingGroupInfoStr: "",
            dingdingGroupTamplateId: "",
            dingdingGroupReplyVisible: true,
            isGroupList: false,
            // 飞书
            feishuDialog: false,
            bindFeishuList: [],
            feishuBindForm: {
                bindType: "",
                corpId: "",
                corpName: "",
            },
            feishuInfoDialogVisible: false,
            loginMode:"",
            loginModeOptions:[],
            channel: "E_WECHAT",
            showNumWeb:1,
            yunzhijiaDrawer:false,
            yunzhijiaDialogFormVisible:false,
            yunzhijiaBindRules: {
              appName: [
                {
                  required: true,
                  message: "请输入应用名称",
                  trigger: "blur",
                },
                { max: 64, message: "长度最长为64个字符", trigger: "blur" },
              ],
              corpName: [
                {
                  required: true,
                  message: "请输入企业名称",
                  trigger: "blur",
                },
                { max: 64, message: "长度最长为64个字符", trigger: "blur" },
              ],
              corpId: [
                {
                  required: true,
                  message: "请输入注册ID",
                  trigger: "blur",
                },
                { max: 36, message: "长度最长为36个字符", trigger: "blur" },
              ],
              appId: [
                {
                  required: true,
                  message: "请输入App Id",
                  trigger: "blur",
                },
                { max: 32, message: "长度最长为64个字符", trigger: "blur" },
              ],
              appSecret: [
                {
                  required: true,
                  message: "请输入App Secret",
                  trigger: "blur",
                },
                { max: 64, message: "长度最长为64个字符", trigger: "blur" },
              ],
              signKey: [
                {
                  required: true,
                  message: "请输入Sign Key",
                  trigger: "blur",
                },
                { max: 64, message: "长度最长为64个字符", trigger: "blur" },
              ],
            },
            yunzhijiaBindForm: {
              appId:"",
              appName:"",
              appSecret:"",
              contactSecret:"",
              corpId:"",
              corpName:"",
              signKey:""
            },
            yunzhijiaList:[],
          yunzhijiaInfoDialogVisible:false
        };
    },
    methods: {
        // 网站接入--设置
        botLinkSet(item){
            console.log(item,'item');
            this.showNumWeb = sessionStorage.setItem('showNumWeb',1);
            this.$router.push({
                name: "botLinkSet",
                query: {
                    webId: item.id,
                    webName: item.agentName,
                    bindCode:item.bindCode
                },
            });
        },
        lookMore(){
            console.log('lookMore');
        },
        onNodeClick(list){
            console.log('onNodeClick', list);
            console.log(this.bindBots, this.activeTreeIndex);
            this.bindBots[this.activeTreeIndex].items = list;
            this.bindBots = [...this.bindBots];
        },
        preserStaff(){
            console.log('preserStaff');
            this.$refs.staffCustomer.setItems();
            this.dialogMemberTree = false;
        },
        // 保存设置
        saveWebSet(e,item) {
            console.log(item,1111111);
            console.log(this.loginModeOptions[0]);
            let objData = {
                domain: this.websiteBindForm.domain,
                websiteName: this.websiteBindForm.websiteName,
                botId:item.botId,
                needLogin:item.needLogin,
                channel:0,
                corpId:item.corpId,
            };
            if(this.loginModeOptions.length === 0){
                objData.channel = null;
            } else {
                this.loginModeOptions.forEach((v,i) => {
                    if(v.corpId == item.corpId){
                        objData.corpName = v.corpName
                        if (v.corpType == 0) {
                            objData.channel = 0
                        }
                        if (v.corpType == 3) {
                            objData.channel = 3
                        }
                    }
                })
            }
            console.log(objData,'objData');
            this.FetchPut(this.requestUrl.websiteBind.updateWebInfo,item.id,objData).then((res) => {
                if (res.code === "0") {
                    this.$message.success('信息更新成功');
                    this.getBindWebsiteListV2();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取主体下的公司
        getCorps4main() {
            this.FetchGet(this.requestUrl.websiteBind.corps4main).then((res) => {
                console.log(res,111111111);
                if (res && res.code === "0") {
                    this.loginModeOptions = res.data || [];
                    console.log(this.loginModeOptions,'loginModeOptions');
                    this.loginMode = this.loginModeOptions[0].corpId;
                    // if (this.loginModeOptions[0].corpType == 0) {
                    //     this.channel = 0
                    // }
                    // if (this.corpType == 3) {
                    //     this.channel = 3
                    // }
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        // 关闭钉钉群list信息
        closeDdgList(){
            console.log("+++++++++++++++++");
            this.$refs.dingdinggroup.getDingdingGroupBindList();
        },
      //部门是否使用通讯录组件
      isOpenData(value){
       /* const res = /^\d+$/;
        console.debug('value',res.test(value))*/
        let bool = false
        if (this.orgTree.length > 1){
          if (this.orgTree[0].originData&&
              this.orgTree[0].originData.wechatBotDTO&&
              this.orgTree[0].originData.wechatBotDTO.bindType===0&&
              this.orgTree[0].originData.wechatBotDTO.wechatType===0){
            bool = true
          }
          else if (this.orgTree[1].originData&&
              this.orgTree[1].originData.wechatBotDTO&&
              this.orgTree[1].originData.wechatBotDTO.bindType===0&&
              this.orgTree[1].originData.wechatBotDTO.wechatType===0){
            bool = true
          }
        }
        else if (this.orgTree.length===1){
          if (this.orgTree[0].originData&&
              this.orgTree[0].originData.wechatBotDTO&&
              this.orgTree[0].originData.wechatBotDTO.bindType===0&&
              this.orgTree[0].originData.wechatBotDTO.wechatType===0){
            bool = true
          }
        }

        return bool
      },
        getGroupTemplateList(){
            this.$refs.dingdinggroup.getDingdingGroupBindList('isGroupList');
        },
        editDingdingGroupList(id, itemStr){
            this.bindedDingdingGroupDialog = true;
            // this.dingdingGroupDialog = false;
            console.log(JSON.parse(itemStr))
            this.dingdingGroupInfoStr = itemStr;
            this.dingdingGroupTamplateId = id;
            this.dingdingGroupReplyVisible = JSON.parse(itemStr).replyVisible;
            this.$nextTick(() => {
                this.$refs.bindeddingdinggroup.getDingdingGroupBindList(id);
            })
        },
        // 获取部门树
        getDepartmentTree(){
          let url = this.requestUrl.bindV2.departmentTree+'?fillOriginData=true'
            this.FetchGet(url).then((res) => {
                if (res.code === "0") {
                    let arr = res.data;
                    if (arr[0] && arr[0].total && arr[0].total > 300) {
                        this.defaultExpandAll = false;
                    } else {
                        this.defaultExpandAll = true;
                    }
                    const formatDepartmentTree = (arr) => {
                        arr.forEach((item) => {
                            item.name = item.label;
                            item.checked = false;
                            if (item.children && item.children != 0) {
                                item.children = formatDepartmentTree(item.children.filter(cell => cell.type!=0 ))
                            }
                        })
                        return arr;
                    }
                    this.orgTree = formatDepartmentTree(arr.filter(item => item.type!=0 ));
                } else {
                  this.$message.error(res.message);
                }
            });
        },
        // 获取上下游数据
        getOrganization(){
          let url = this.requestUrl.wechatKF.selectOrganization;
            this.FetchGet(url).then((res) => {
                if (res.code === "0") {
                    let arr = res.data;
                    this.defaultExpandAll = true;
                    const formatDepartmentTree = (arr) => {
                        arr.forEach((item) => {
                            item.checked = false;
                            if (item.children && item.children != 0) {
                                item.children = formatDepartmentTree(item.children)
                            }
                        })
                        return arr;
                    }
                    this.orgTree = formatDepartmentTree(arr);
                } else {
                  this.$message.error(res.message);
                }
            });
        },
        changeItemScope(e){
            console.log(this.bindBots);
            this.bindBots = [...this.bindBots];
        },
        closeBindBotDialog(){
            this.getBotList();
        },
        // 获取机器人列表
        getBotList() {
          this.FetchGet(this.requestUrl.bot.getBotList, {
            page: 1,
            size: 100,
          }).then((res) => {
            if (res.code === "0") {
              this.noBindBots = res.data.list;
              this.noBindBots.forEach(bot => {
                  bot.isChecked = false;
                  bot.scope = 0;
                  bot = JSON.parse(JSON.stringify(bot));
              })
            } else {
              this.$message.error(res.message);
            }
            this.loading = false;
          });
        },
        bindBotV2(appType, handleType, item, isGroupList){ // appType: website wechatGZ handleType: add edit
            console.log(appType, handleType, item, isGroupList);
            this.activeCorpId = item.corpId;
            console.log(this.activeCorpId);
            this.bindBotsAppType = appType;
            this.bindActiveAppItem = item;
            this.bindBotDialogVisible = true;
            this.bindBots = [...[]];
            if (isGroupList) {
                this.isGroupList = true;
            } else {
                this.isGroupList = false;
            }
            if (handleType == 'edit') {
                this.bindBots = JSON.parse(JSON.stringify(item.bindBot.botInfos));
                this.bindBots.forEach((botCell, index) => {
                    this.noBindBots.forEach((cell, cellIndex) => {
                        if (botCell.botId == cell.id || cell.botId == botCell.botId) {
                            cell.isChecked = true;
                        }
                    })
                })
            }
        },
        // 微信客服接入组件化整理
        bindBotV2Children(obj){ // appType: website wechatGZ handleType: add edit
            let appType = obj.appType;
            let handleType = obj.handleType;
            let item = obj.item;
            this.bindBotV2(appType, handleType, item)
        },
        changeCheckedOrg(node, data, index) {
            if (data.checked) {
                this.bindBots[index].items.unshift(data);
            } else {
                this.bindBots[index].items.forEach((cell, cellIndex) => {
                    if (cell.id == data.id) {
                        this.bindBots[index].items.splice(cellIndex, 1);
                    }
                });
            }
            this.bindBots[index].items = [...this.bindBots[index].items];
            this.bindBots = [...this.bindBots];
        },
        handleCloseOrganization(index, tagCellIndex) {
            this.initShowOrTree(
                [this.bindBots[index].items[tagCellIndex].id],
                false,
                "close"
            );
            this.bindBots[index].items.splice(tagCellIndex, 1);
        },
        initShowOrTree(handleIdArr, isChecked, type) {
            const formOrgTree = (arr, handleIdArr, isChecked) => {
                if (type == "close") {
                    arr.forEach((item) => {
                        if (handleIdArr.indexOf(item.id) != -1) {
                            item.checked = isChecked;
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        } else {
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        }
                    });
                } else {
                    arr.forEach((item) => {
                        if (handleIdArr.indexOf(item.id) != -1) {
                            item.checked = isChecked;
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        } else {
                            item.checked = !isChecked;
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        }
                    });
                }

                return arr;
            };
            this.orgTree = [
                ...formOrgTree(this.orgTree, handleIdArr, isChecked),
            ];
        },
        setActiveTreeIndex(index) {
            let arr = [];
            if (this.bindBots[index].items == null) {
                this.bindBots[index].items = [];
            }
            this.bindBots[index].items.forEach((item) => {
                arr.push(item.id);
            })
            setTimeout(() => {
                console.log("+++++++++______++++++++", index)
                if (this.isFwh) {
                    this.initShowOrTree(arr, true, "init");
                }
                
                this.activeTreeIndex = index;
                this.selectIds = [...arr];
                this.activeBindBotsItems =  [...this.bindBots[index].items];
                if (!this.isFwh) {
                    this.dialogMemberTree = true;
                }
            }, 100);
        },
        selectOrg(data, checked, indeterminate) {
            let treeId = "tree" + this.activeTreeIndex;
            this.bindBots[this.activeTreeIndex].items = this.$refs[
                treeId
            ][0].getCheckedNodes();
        },
        // 关闭绑定机器人
        cancelNewBindBot() {
            this.bindBotDialogVisible = false;
        },
        // 提交绑定机器人
        submitNewBindBot() {
            console.log(this.bindBots);
            let botsArr = [];
            this.bindBots.forEach(bot => {
                if (this.bindBotsAppType == 'website' || this.bindBotsAppType == 'wxServ') {
                    botsArr.push({
                        apiKey: bot.apiKey,
                        botId: bot.id || bot.botId,
                        name: bot.name,
                        scope: 0
                    })
                } else {
                    botsArr.push({
                        apiKey: bot.apiKey,
                        botId: bot.id || bot.botId,
                        name: bot.name,
                        scope: bot.scope,
                        items: bot.items
                    })
                }
                
            })
            if (botsArr.length == 0) {
                if (this.bindBotsAppType == 'website') {
                    this.$message.error("请绑定一个机器人");
                } else {
                    this.$message.error("请至少绑定一个机器人");
                }
            } else {
                let flag = true;
                botsArr.forEach(item => {
                    if (item.scope == 1) {
                        console.log(item.items);
                        if (item.items == null || item.items.length == 0) {
                            this.$message.error("请选择组织结构！");
                            flag = false;
                        }
                    }
                })
                if (this.bindBotsAppType == 'website' && botsArr.length > 1) {
                    this.$message.error("网站接入最多绑定一个机器人");
                } else {
                    flag && this.FetchPost(
                    this.requestUrl.bindV2.addBindBot,
                    {
                        bindCode: this.bindActiveAppItem.bindCode,
                        bindId: this.bindActiveAppItem.id,
                        botInfos: botsArr
                    }
                    ).then((res) => {
                        if (res.code === "0") {
                            this.bindBotDialogVisible = false;
                            if (this.bindBotsAppType == 'website') {
                                this.getBindWebsiteListV2();
                            } else {
                                // 钉钉群
                                if (this.dingdingGroupDialog) {
                                    this.$refs.dingdinggroup.getDingdingGroupBindList();
                                }
                                if (this.isGroupList) {
                                    this.$refs.bindeddingdinggroup.getDingdingGroupBindList();
                                    this.$refs.dingdinggroup.getDingdingGroupBindList('isGroupList');
                                }
                                // 飞书
                                if (this.feishuDialog) {
                                    this.getFeishuBindList();
                                }
                                this.getBindList();
                                this.getDingdingBindList();
                                this.getDingdingSingBindList();
                                this.getBindWechatList();
                                this.getYunzhijiaBindList();
                                // 微信客服
                                if (this.wxKfDialog) {
                                    this.$refs.wxkf.getWxKfAppList();
                                }
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                        this.loading = false;
                    });
                }
            }
            
        },
        // 选择待选机器人
        handleCheckedBotsChange(arr) {
            console.log(arr);
            this.noBindBots.forEach((item, index) => {
                if (arr.indexOf(item.id) != -1 && !item.isChecked) {
                    item.scope = 0;
                    this.bindBots.push(item);
                    this.noBindBots.splice(index, 1);
                    setTimeout(() => {
                        this.checkedBots = [];
                    }, 200);
                }
            });
        },
        // 取消选择机器人
        deleteBindBotToNo(index) {
            this.bindBots[index].id = this.bindBots[index].botId?this.bindBots[index].botId:this.bindBots[index].id;
            this.noBindBots.push(this.bindBots[index]);
            this.bindBots.splice(index, 1);
            let tampArr = JSON.parse(JSON.stringify(this.noBindBots))
            this.noBindBots = [...[]];
            this.noBindBots = tampArr;
            console.log(this.noBindBots);
        },
        // 绑定钉钉单聊
        bindDingdingSing(){
            this.dingdingSingDialogFormVisible = true;
            this.dingdingSingBindForm = {
                agentId: "",
                corpId: "",
                corpName: "",
                secret: "",
                appKey: "",
            };
        },
        // 绑定钉钉
        bindDingding() {
            this.dingdingDialogFormVisible = true;
            this.dingdingBindForm = {
                agentId: "",
                corpId: "",
                corpName: "",
                secret: "",
                appKey: "",
            };
        },
        // 绑定钉钉群
        bindDingdingGroup() {
            this.dingdinggroupFormVisible = true;
            this.dingdingGroupBindForm = {
                agentId: "",
                corpId: "",
                corpName: "",
                secret: "",
                appKey: "",
            };
        },
        //云之家绑定
        bindYunzhijia(){
          this.yunzhijiaDialogFormVisible = true;
          this.yunzhijiaBindForm = {
            appId:"",
            appName:"",
            appSecret:"",
            contactSecret:"",
            corpId:"",
            corpName:"",
            signKey:""
          }
        },
        // 钉钉单聊绑定企业
        getAuthUrlDingdingSing() {
            this.dingdingSingDialogFormVisible = true;
            if (
                this.dingdingSingBindForm.agentId &&
                this.dingdingSingBindForm.corpId &&
                this.dingdingSingBindForm.corpName &&
                this.dingdingSingBindForm.secret &&
                this.dingdingSingBindForm.appKey &&
                this.dingdingSingBindForm.agentName
            ) {
                this.FetchPost(
                    this.requestUrl.dingdingSingBind.getDingtalkSingAuthUrl,
                    {
                        agentId: this.dingdingSingBindForm.agentId.trim(),
                        corpId: this.dingdingSingBindForm.corpId.trim(),
                        corpName: this.dingdingSingBindForm.corpName.trim(),
                        appSecret: this.dingdingSingBindForm.secret.trim(),
                        appKey: this.dingdingSingBindForm.appKey,
                        agentName: this.dingdingSingBindForm.agentName,
                        isCheckAgent: false,
                    }
                ).then((res) => {
                    if (res.code === "0") {
                        this.authUrl = res.data;
                        this.$message.success("绑定成功");
                        this.dingdingSingDialogFormVisible = false;
                        this.getDingdingSingBindList();
                        this.dingdingSingBindForm.agentId = "";
                        this.dingdingSingBindForm.corpId = "";
                        this.dingdingSingBindForm.corpName = "";
                        this.dingdingSingBindForm.secret = "";
                        this.dingdingSingBindForm.appKey = "";
                        this.dingdingSingBindForm.agentName = "";
                    } else if (res.code === "0OPBE0006") {
                        this.warningVisible = true;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            } else {
                this.$message.error("绑定信息不能为空，请检查！");
            }
        },
        // 钉钉绑定企业
        getAuthUrlDingding() {
            this.dingdingDialogFormVisible = true;
            // let botId = this.$route.query.id;
            // let apiKey = this.$route.query.apiKey;
            if (
                this.dingdingBindForm.agentId &&
                this.dingdingBindForm.corpId &&
                this.dingdingBindForm.corpName &&
                this.dingdingBindForm.secret &&
                this.dingdingBindForm.appKey &&
                this.dingdingBindForm.agentName
            ) {
                this.FetchPost(
                    this.requestUrl.dingdingBind.getDingtalkAuthUrl,
                    {
                        agentId: this.dingdingBindForm.agentId.trim(),
                        corpId: this.dingdingBindForm.corpId.trim(),
                        corpName: this.dingdingBindForm.corpName.trim(),
                        appSecret: this.dingdingBindForm.secret.trim(),
                        appKey: this.dingdingBindForm.appKey,
                        agentName: this.dingdingBindForm.agentName,
                        isCheckAgent: false,
                    }
                ).then((res) => {
                    if (res.code === "0") {
                        this.authUrl = res.data;
                        this.$message.success("绑定成功");
                        this.dingdingDialogFormVisible = false;
                        this.getDingdingBindList();
                        this.dingdingBindForm.agentId = "";
                        this.dingdingBindForm.corpId = "";
                        this.dingdingBindForm.corpName = "";
                        this.dingdingBindForm.secret = "";
                        this.dingdingBindForm.appKey = "";
                        this.dingdingBindForm.agentName = "";
                    } else if (res.code === "0OPBE0006") {
                        this.warningVisible = true;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            } else {
                this.$message.error("绑定信息不能为空，请检查！");
            }
        },
        // 钉钉群绑定企业
        getAuthUrlDingdingGroup() {
            this.dingdinggroupFormVisible = true;
            // let botId = this.$route.query.id;
            // let apiKey = this.$route.query.apiKey;
            if (
                this.dingdingGroupBindForm.agentId &&
                this.dingdingGroupBindForm.corpId &&
                this.dingdingGroupBindForm.corpName &&
                this.dingdingGroupBindForm.secret &&
                this.dingdingGroupBindForm.appKey &&
                this.dingdingGroupBindForm.agentName
            ) {
                this.FetchPost(
                    this.requestUrl.dingdingBind.getDingtalkAuthUrl, // todo 替换为钉钉群
                    {
                        agentId: this.dingdingGroupBindForm.agentId.trim(),
                        corpId: this.dingdingGroupBindForm.corpId.trim(),
                        corpName: this.dingdingGroupBindForm.corpName.trim(),
                        appSecret: this.dingdingGroupBindForm.secret.trim(),
                        appKey: this.dingdingGroupBindForm.appKey,
                        agentName: this.dingdingGroupBindForm.agentName,
                        isCheckAgent: false,
                    }
                ).then((res) => {
                    if (res.code === "0") {
                        this.authUrl = res.data;
                        this.$message.success("绑定成功");
                        this.dingdinggroupFormVisible = false;
                        this.getDingdingGroupBindList();
                        this.dingdingGroupBindForm.agentId = "";
                        this.dingdingGroupBindForm.corpId = "";
                        this.dingdingGroupBindForm.corpName = "";
                        this.dingdingGroupBindForm.secret = "";
                        this.dingdingGroupBindForm.appKey = "";
                        this.dingdingGroupBindForm.agentName = "";
                    } else if (res.code === "0OPBE0006") {
                        this.warningVisible = true;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            } else {
                this.$message.error("绑定信息不能为空，请检查！");
            }
        },

        //云之家绑定企业
        getAuthUrlYunzhijia(){
          if (this.yunzhijiaBindForm.corpName &&
              this.yunzhijiaBindForm.corpId &&
              this.yunzhijiaBindForm.appId &&
              this.yunzhijiaBindForm.appName &&
              this.yunzhijiaBindForm.appSecret &&
              this.yunzhijiaBindForm.signKey){
            this.FetchPost(this.requestUrl.dingdingBind.getYunzhijiaAuthUrl,this.yunzhijiaBindForm).then(res=>{
              console.debug('res',res)
              if (res.code == '0'){
                this.authUrl = res.data;
                this.$message.success("绑定成功");
                this.getYunzhijiaBindList();
                this.yunzhijiaDialogFormVisible = false;
              }
            })
          } else {
            this.$message({
              message:"绑定信息不能为空,请检查",
              type:"error",
              duration:2000
            })
          }
        },
        // 查看钉钉单聊已绑定企业信息
        showDingdingSingBindInfo(item) {
            this.dingdinSingInfoDialogVisible = true;
            this.dingdingSingBindForm = item;
        },
        // 查看钉钉已绑定企业信息
        showDingdingBindInfo(item) {
            this.dingdinInfoDialogVisible = true;
            this.dingdingBindForm = item;
        },
        // 查看钉钉群已绑定企业信息
        showDingdingGroupBindInfo(item) {
            this.dingdinGroupInfoDialogVisible = true;
            this.dingdingGroupBindForm = item;
        },
        //查看云之家已绑定企业信息
        showYunzhijiaBindInfo(item){
          console.debug('item',item)
          this.yunzhijiaInfoDialogVisible = true;
          this.yunzhijiaBindForm = item;
        },
        // 钉钉单聊应用解绑
        postDingdingSingUnbind(id) {
            this.$confirm(
                "解绑后，该自建应用将无法继续使用该机器人，是否继续解绑？"
            )
                .then((_) => {
                    this.FetchPost(this.requestUrl.dingdingBind.unBind, {
                        bindId: id,
                    }).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("解绑成功");
                            this.getDingdingSingBindList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        // 钉钉应用解绑
        postDingdingUnbind(id,type) {
            this.$confirm(
                "解绑后，该自建应用将无法继续使用该机器人，是否继续解绑？"
            )
                .then((_) => {
                    this.FetchPost(this.requestUrl.dingdingBind.unBind, {
                        bindId: id,
                    }).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("解绑成功");
                            if (type === 'yunzhijia'){
                              this.getYunzhijiaBindList();
                            } else {
                              this.getDingdingBindList();
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        // 钉钉群应用解绑
        postDingdingGroupUnbind(id) {
            this.$confirm(
                "解绑后，该自建应用将无法继续使用该机器人，是否继续解绑？"
            )
                .then((_) => {
                    this.FetchPost(this.requestUrl.dingdingBind.unBind, { // todo 改为钉钉群
                        bindId: id,
                    }).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("解绑成功");
                            this.getDingdingGroupBindList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        // 获取钉钉单聊绑定列表
        getDingdingSingBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "9").then((res) => {
                if (res.code === "0") {
                    this.dingdingSingH5AppList = [...res.data];
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取钉钉绑定列表
        getDingdingBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "3").then((res) => {
                if (res.code === "0") {
                    this.dingdingH5AppList = [...res.data];
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取钉钉群绑定列表
        getDingdingGroupBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "3").then((res) => { // todo 改为请求钉钉群
                if (res.code === "0") {
                    this.dingdingGroupAppList = [...res.data];
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取Ip地址
        getBindDingdingIp() {
            this.FetchGet(
                this.requestUrl.dingdingBind.dingtalkWhitelist,
                {}
            ).then((res) => {
                if (res.code === "0") {
                    this.dingtalkWhite = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取云之家绑定列表
        getYunzhijiaBindList() {
          this.FetchGet(this.requestUrl.bindV2.channelAccessList + "13").then((res) => {
            if (res.code === "0") {
              this.yunzhijiaList = [...res.data];
            } else {
              this.$message.error(res.message);
            }
          });
        },
        addDingdingMp() {
            this.isFwh = false;
            this.getDepartmentTree();
            this.dingdingDialog = true;
            this.getDingdingBindList();
            this.getBindDingdingIp();
        },
        // 点击钉钉群面板
        addDingdingGroup(){
            this.isFwh = false;
            this.getDepartmentTree();
            this.dingdingGroupDialog = true;
            this.$nextTick(() => {
                this.$refs.dingdinggroup.getDingdingGroupBindList();
            })
            // this.getBindDingdingIp();
        },
        // 接入钉钉单聊
        addDingdingSing() {
            this.isFwh = false;
            this.getDepartmentTree();
            this.dingdingSingDialog = true;
            this.getDingdingSingBindList();
            this.getBindDingdingIp();
        },
        // 点击云之家
        adYunzhijiaMp(){
          this.isFwh = false;
          this.getDepartmentTree();
          this.yunzhijiaDrawer = true;
          this.getYunzhijiaBindList();
        },
        // 微信客服弹窗
        addWxKfMp(){
            this.wxKfDialog = true;
        },
        // 钉钉应用复制链接
        copyDingdingUrl(urlType, copyclass, url) {
            var Url2 = document.getElementById(urlType).innerText;
            console.log(Url2);
            var copyclass = document.getElementById(copyclass);
            console.log(copyclass);
            var oInput = document.createElement("input");
            // oInput.value = Url2;
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message.success("复制成功！");
        },
        showtips() {
            this.$message.warning(
                "该公众号为订阅号，只有认证的服务号才可推送消息！"
            );
        },
        // 更改推送
        changeAllowPushMsg($event, item) {
            console.log($event, item);
            this.FetchPost(
                this.requestUrl.wechatBind.changeAllowPushMsg +
                    item.id +
                    "?status=" +
                    $event
            ).then((res) => {
                if (res.code === "0") {
                    console.log(res.data);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //获取机器人设置状态
        getBotInfo() {
            this.FetchGet(this.requestUrl.bot.getBotList, {
                page: 1,
                size: 1000,
            }).then((res) => {
              if (res.code === "0") {
                this.miniAppBots = res.data.list;
                this.miniAppBots.forEach(bot => {
                    if (bot.setting.showAppletEntrance == null) {
                        bot.setting.showAppletEntrance = false;
                    }
                })
              } else {
                this.$message.error(res.message);
              }
              this.loading = false;
            });
        },
        //提交小程序设置
        updateBot(e, id) {
            let update = {
                botSetting: {
                    showAppletEntrance: e,
                },
            };
            console.log(update);
            this.FetchPut(
                this.requestUrl.bot.updateBot,
                id,
                update
            ).then((res) => {
                if (res.code === "0") {
                    this.getBotInfo();
                    this.$message.success("更新成功！");
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 下载/预览小程序码
        miniAppCodeHandle(index, id, type){
            this.FetchGet(this.requestUrl.miniProgramBind.wxMiniCode, {
                bid: id,
            }).then((res) => {
                if (res.code === "0") {
                    if (type == "download") {
                        window.location = res.data;
                    } else {
                        this.miniAppBots[index].miniAppCodeUrl = res.data;
                        this.miniAppBots = [...this.miniAppBots];
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 批量上传
        // 上传成功后的回调
        uploadSuccess(response, file, fileList) {
            this.$message.success("上传成功");
            console.log(response, file, fileList);
            // if (response.code === "0") {
            //   this.progressDialogVisible = true;
            //   this.uploadRequestUUID = response.data;
            //   this.reqProcessTimer = setInterval(() => {
            //     this.requestUploadProcess();
            //   }, 2000);
            // }
        },
        requestUploadProcess() {
            if (
                this.progressDialogVisible == null ||
                this.progressDialogVisible === false
            ) {
                clearInterval(this.reqProcessTimer);
                return;
            }
            this.FetchGet(
                this.requestUrl.intentQuestion.batchuploadIntentQProcess,
                {
                    uid: this.uploadRequestUUID,
                }
            ).then((res) => {
                if (res.code === "0") {
                    this.uploadProgress = res.data;
                    if (res.data == 100) {
                        this.progressDialogVisible = false;
                        clearInterval(this.reqProcessTimer);
                        this.currentPage = 1;
                        this.getIntentQList();
                    }
                } else {
                    this.$message.error(res.message);
                    this.progressDialogVisible = false;
                }
            });
        },
        // 上传错误
        uploadError(response, file, fileList) {
            if (response.code !== "0") {
                this.$message.error("上传失败，请重试!");
            }
        },
        // 上传前对文件的大小的判断
        beforeAvatarUpload(file) {
            console.log(file.name);
            const extension = file.name.split(".")[1] === "txt";
            // const extension2 = file.name.split(".")[1] === "xlsx";
            // const extension3 = file.name.split(".")[1] === "doc";
            // const extension4 = file.name.split(".")[1] === "docx";
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!extension) {
                this.$message.error("上传模板只能是 txt 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传模板大小不能超过 2MB!");
            }
            return extension && isLt2M;
        },
        resetStatus() {
            this.warningVisible = false;
        },
        cancelForm() {
            this.loading = false;
            this.dialog = false;
            clearTimeout(this.timer);
        },
        handleClose(done) {
            this.$confirm("还有未保存的工作哦确定关闭吗？")
                .then((_) => {
                    done();
                })
                .catch((_) => {});
        },
        // 查看绑定信息
        showBindInfo(item) {
            this.infoDialogVisible = true;
            this.bindForm = item;
        },
         // 查看绑定信息
        showFeishuBindInfo(item) {
            this.feishuInfoDialogVisible = true;
            this.feishuBindForm = item;
        },
        // 复制域名
        copyDomain(urlType, copyclass) {
            var Url2 = document.getElementById(urlType).innerText;
            var copyclass = document.getElementById(copyclass);
            var oInput = document.createElement("input");
            oInput.value = Url2;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message.success("复制成功！");
            oInput.className = "oInput";
            oInput.style.display = "none";
            copyclass.className = "copyEnd";
            copyclass.innerHTML = "已复制";
            setTimeout(function () {
                copyclass.className = "copy";
                copyclass.innerHTML = "复制";
            }, 3000);
        },
        // 复制链接
        copy(urlType, copyclass, url) {
            console.log(url);
            var Url2 = document.getElementById(urlType).innerText;
            console.log(Url2);
            var copyclass = document.getElementById(copyclass);
            console.log(copyclass);
            var oInput = document.createElement("input");
            // oInput.value = Url2;
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message.success("复制成功！");
        },
        getAuthDomain() {
            let botId = this.$route.query.id;
            this.FetchGet(this.requestUrl.eWechatBind.getAuthDomain, {
                botId: botId,
            }).then((res) => {
                if (res.code === "0") {
                    this.authDomain = res.data;
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        },
        getOpenDomain() {
            let botId = this.$route.query.id;
            this.FetchGet(this.requestUrl.dingdingBind.getOpenDomain, {}).then(
                (res) => {
                    if (res.code === "0") {
                        this.openDomain = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }
            );
        },
        addBindMiniProg() {
            this.isFwh = false;
            this.xcxdrawer = true;
            this.getBotInfo();
        },
        addBindAPP() {
            this.isFwh = false;
            this.APPdrawer = true;
        },

        addBindWebsite() {
            this.showNumWeb++;
            this.showNumWeb = sessionStorage.setItem('showNumWeb',this.showNumWeb);
            this.websitedrawer = true;
            this.isFwh = false;
            this.getDepartmentTree();
            // this.getBindWebsiteList();
            this.getBindWebsiteListV2();
        },
        //获取绑定列表
        getBindWebsiteListV2() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "/4").then((res) => {
                if (res.code === "0") {
                    console.log(this.loginModeOptions,2222);
                    res.data.forEach((item,index) => {
                        if(!item.corpId || item.corpId === '' || item.corpId === null){
                            item.corpId = this.loginModeOptions[0]&&this.loginModeOptions[0].corpId || '';
                            item.iconType = this.loginModeOptions[0]&&this.loginModeOptions[0].corpType || null;
                        } else {
                            this.loginModeOptions.forEach(v => {
                                if(v.corpId === item.corpId){
                                    item.iconType = v.corpType;
                                }
                            })
                        }
                    })
                    this.bindWebsiteList = res.data;
                    console.log(this.bindWebsiteList,2222222);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        addWebsiteBindV2() {
            let objData = {
                domain: this.websiteBindForm.domain,
                websiteName: this.websiteBindForm.websiteName,
                channel:0,
                corpId:this.loginModeOptions[0]&&this.loginModeOptions[0].corpId||'',
                corpName:this.loginModeOptions[0]&&this.loginModeOptions[0].corpName||''
            };
            if(this.loginModeOptions.length > 0){
                 if (this.loginModeOptions[0]&&this.loginModeOptions[0].corpType == 0) {
                    objData.channel = 0
                }
                if (this.loginModeOptions[0]&&this.loginModeOptions[0].corpType == 3) {
                    objData.channel = 3
                }
            } else {
                objData.channel = null
            }
            console.log(objData);
            this.FetchPost(
                this.requestUrl.bindV2.addWebsiteApp,
                objData,
                { emulateJSON: true }
            ).then((res) => {
                if (res.code === "0") {
                    this.$message.success("添加成功");
                    this.addWebsiteDialogVisible = false;
                    this.websiteBindForm.domain = "";
                    this.websiteBindForm.websiteName = "";
                    // this.getBindWebsiteList();
                    this.getBindWebsiteListV2();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        addWebsiteBind() {
            let objData = {
                botId: this.$route.query.id,
                domain: this.websiteBindForm.domain,
                websiteName: this.websiteBindForm.websiteName,
            };
            console.log(objData);
            this.FetchPost(
                this.requestUrl.websiteBind.addWebsiteBind,
                objData,
                { emulateJSON: true }
            ).then((res) => {
                if (res.code === "0") {
                    this.$message.success("添加成功");
                    this.addWebsiteDialogVisible = false;
                    this.websiteBindForm.domain = "";
                    this.websiteBindForm.websiteName = "";
                    // this.getBindWebsiteList();
                    this.getBindWebsiteListV2();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // Copy成功
        onCopy(e) {
            this.$message.success("内容已复制到剪切板！");
        },
        // Copy失败
        onError(e) {
            this.$message.error("抱歉，复制失败！");
        },
        // 删除绑定网站
        deleteWebsiteBind(id) {
            console.log("id:" + id);
            this.$confirm(
                "删除后，该网站将无法继续使用该机器人，是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    this.FetchPost(
                        this.requestUrl.bindV2.unbindApp,
                        {bindId: id}
                    ).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("删除成功");
                            // this.getBindWebsiteList();
                            this.getBindWebsiteListV2();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 点击飞书接入面板
        getFeishuList() {
            this.feishuDialog = true;
            this.getFeishuBindList();
            // this.bindForm.agentId = "";
            // this.bindForm.bindType = "";
            // this.bindForm.corpId = "";
            // this.bindForm.corpName = "";
            // this.bindForm.secret = "";
        },

        // 点击新建绑定机器人
        addBindCompany() {
            this.dialog = true;
            this.isFwh = false;
            this.getDepartmentTree();
            this.getBindList();
            this.bindForm.agentId = "";
            this.bindForm.bindType = "";
            this.bindForm.corpId = "";
            this.bindForm.corpName = "";
            this.bindForm.secret = "";
        },
        getAuthUrl() {
            this.dialogFormVisible = true;
            if (
                (this.bindForm.agentId,
                this.bindForm.corpId,
                this.bindForm.corpName,
                this.bindForm.secret)
            ) {
                this.FetchGet(this.requestUrl.eWechatBind.getAuthUrl, {
                    agentId: this.bindForm.agentId.trim(),
                    bindType: this.bindForm.bindType || "",
                    corpId: this.bindForm.corpId.trim(),
                    corpName: this.bindForm.corpName.trim(),
                    secret: this.bindForm.secret.trim(),
                }).then((res) => {
                    if (res.code === "0") {
                        this.authUrl = res.data;
                        this.$message.success("绑定成功");
                        this.dialogFormVisible = false;
                        this.getBindList();
                        this.bindForm.agentId = "";
                        this.bindForm.bindType = "";
                        this.bindForm.corpId = "";
                        this.bindForm.corpName = "";
                        this.bindForm.secret = "";
                        console.log(this.bindForm.corpName);
                    } else if (res.code === "0OPBE0006") {
                        this.warningVisible = true;
                    } else {
                        // this.$message.error(res.message);
                        this.$message.error("绑定失败，请检查填写内容");
                    }
                    this.loading = false;
                });
            } else {
                this.$message.error("绑定信息不能为空，请检查！");
            }
        },
        //获取绑定列表
        getBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + '0').then((res) => {
                if (res.code === "0") {
                    this.bindList = res.data;
                    this.bindList = [...res.data];
                    console.log(this.bindList);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //获取绑定列表
        getFeishuBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + '10').then((res) => {
                if (res.code === "0") {
                    this.bindFeishuList = res.data;
                    this.bindFeishuList = [...res.data];
                    console.log(this.bindFeishuList);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 应用解绑
        postUnbind(id) {
            console.log(id);
            this.$confirm(
                "解绑后，该自建应用将无法继续使用该机器人，是否继续解绑？"
            )
                .then((_) => {
                    this.FetchPost(this.requestUrl.eWechatBind.postUnbind, {
                        bindId: id,
                    }).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("解绑成功");
                            this.getBindList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        //===============================================================公众号绑定===============================================================
        addWechatMp() {
            this.getOrganization();
            this.isFwh = true;
            this.wechatMpBindForm = {};
            this.wechatmpdialog = true;
            this.getBindWechatList();
        },
        // 查看绑定信息
        showWechatMpBindInfo(item) {
            this.wechatMpShowDialogVisible = true;
            this.wechatMpBindForm = item;
        },
        // 删除公众号绑定
        deleteWechatMpBind(bindId) {
            this.$confirm(
                "请前往微信微信公众号后台进行解绑，解绑后机器人将不再接收用户消息，是否继续解绑？"
            )
                .then((_) => {
                    this.FetchPost(this.requestUrl.wechatBind.unbind, {
                        bindId: bindId,
                    }).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("解绑成功");
                            this.getBindWechatList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        selectAuthType(type) {
            if (type == "0") {
                //第三方方法
                this.wechatMpAuthDialog = false;
                // this.publicThirdAuthType=this.$refs.authTypeInfo.dataset.authtype;
                // console.log(this.publicThirdAuthType);
                this.bindWechatByThirdAuth();
            } else if (type == "1") {
                this.wechatMpAuthDialog = false;
                this.developerDialog = true;
            }
        },
        getBindWechatList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "1").then((res) => {
                if (res.code === "0") {
                    this.wechatMpbindList = res.data;
                    this.wechatMpbindList = [...res.data];
                    console.log(this.wechatMpbindList);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        bindWechatBySecret() {
            // let botId = this.$route.query.id;
            this.FetchGet(this.requestUrl.wechatBind.getAuthUrl, {
                // botId: botId,
                bindType: 1,
                appId: this.wechatMpBindForm.agentId.trim(),
                appName: this.wechatMpBindForm.agentName.trim(),
                secret: this.wechatMpBindForm.secret.trim(),
            }).then((res) => {
                if (res.code === "0") {
                    this.$message.success("绑定成功");
                    this.developerDialog = false;
                    this.getBindWechatList();
                    this.wechatMpBindForm = {};
                } else {
                    this.$message.error("绑定失败，请检查填写内容");
                }
            });
        },
        bindWechatByThirdAuth(e) {
            this.publicThirdAuthType = this.$refs.authTypeInfo.dataset.authtype;
            console.log(this.publicThirdAuthType);
            // let botId = this.$route.query.id;
            let currentUrl = window.location.href;
            if (currentUrl.indexOf("tabIndex") == -1) {
                currentUrl += "?tabIndex=bot-access";
            }
            if (currentUrl.indexOf("bindType") == -1) {
                currentUrl += "&bindType=mp";
            }
            console.log("get redirect url:", currentUrl);
            this.FetchGet(this.requestUrl.wechatBind.getAuthUrl, {
                // botId: botId,
                bindType: 0,
                currentUrl: currentUrl,
                authType: this.publicThirdAuthType, //1:公众号 2：小程序 3：公众号和小程序
            }).then((res) => {
                if (res.code === "0") {
                    if (res.data) {
                        location.href = res.data;
                        // window.open(res.data);
                    }
                } else {
                    this.$message.error("绑定失败，请检查填写内容");
                }
            });
        },
        //===============================================================================================================================================
        // 点击操作部分
        bindHandleCommand(command) {
            if (command.type === 1) {
                this.dialogVisible = true;
                this.agentId = this.bindList[command.index].agentId;
                this.agentName = this.bindList[command.index].agentName;
                this.corpName = this.bindList[command.index].corpName;
                this.corpId = this.bindList[command.index].corpName;
                this.secret = this.bindList[command.index].secret;
            }
            if (command.type === 2) {
            }
        },
    },
    mounted() {
        this.getCorps4main();
        window.addEventListener("click", () => {
            if (this.isFwh) {
                this.activeTreeIndex = null;
            }
        });
        //this.getEWeChatAuth();
        // this.getAuthUrl();
        this.$nextTick(() => {
            let bindType = this.$route.query.bindType;
            console.log("current bind type:", bindType);
            if (bindType == "mp") {
                this.wechatmpdialog = true;
                this.getBindWechatList();
            }
        });
        this.getBotList();
        this.getAuthDomain();
        this.getOpenDomain();
        this.getDepartmentTree();
        if(this.$route.query.isback && this.$route.query.isback == 1){
            this.showNumWeb = sessionStorage.getItem('showNumWeb');
            if(this.showNumWeb == 1){
                this.addBindWebsite();
            }
        }
    },
};
</script>
<style lang="less">
@import "./../../assets/less/botIndex/botLinkV2.less";
#botIntent .el-dialog__wrapper {
    overflow-y: auto !important;
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
</style>