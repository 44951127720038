var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"dingding-group-list"}},[_c('div',{staticClass:"dingding-group-header"},[_vm._m(0),_c('el-button',{attrs:{"size":"mini","type":"primary","plain":"","round":""},on:{"click":_vm.asynToAllGroup}},[_vm._v("同步模版机器人到所有群")])],1),_c('div',{staticClass:"dingding-group-content"},[_c('div',{staticClass:"active-modal-bots"},[_vm._l((_vm.c_activeModalBots),function(bot,index){return _c('span',{key:index},[(index != 0)?_c('span',[_vm._v("、")]):_vm._e(),_vm._v(_vm._s(bot.name))])}),_c('span',{staticClass:"el-icon-s-tools",on:{"click":function($event){_vm.bindBotV2(
                        'eWx',
                        'edit',
                        JSON.parse(_vm.dingdingGroupInfoStr)
                    )}}})],2),_c('div',{staticClass:"modal-list"},[_c('div',{staticClass:"bindingding-group-list"},[_c('div',{staticClass:"itemHeader"},[_c('div',{staticClass:"companyItem"},[_c('div',{staticClass:"companyItemName"},[_vm._v("已绑定钉钉群名称")]),_c('div',{staticClass:"companyItemName"},[_vm._v("群ID")]),_c('div',{staticClass:"companyItemTime"},[_vm._v("绑定时间")]),_c('div',{staticClass:"companyItemOperition"},[_vm._v("操作")]),_c('div',{staticClass:"bindBot"},[_vm._v("绑定机器人")]),_c('div',{staticClass:"reVisible"},[_c('span',[_vm._v("回复是否全员可见")]),_c('el-switch',{attrs:{"active-color":"#366AFF","inactive-color":"#A9B3C6"},on:{"change":_vm.changeBatchSwitch},model:{value:(_vm.replyVisible),callback:function ($$v) {_vm.replyVisible=$$v},expression:"replyVisible"}})],1)])]),_c('div',{staticClass:"itemContent"},_vm._l((_vm.dingdingGroupList),function(item){return _c('div',{key:item.id,staticClass:"companyItem"},[_c('div',{staticClass:"companyItemName"},[_vm._v(" "+_vm._s(item.dingTalkGroupInfo.groupName)+" ")]),_c('div',{staticClass:"companyItemName"},[_vm._v(" "+_vm._s(item.openConversationId)+" ")]),_c('div',{staticClass:"companyItemTime"},[_vm._v(" "+_vm._s(new Date(item.updateTime).Format( "yyyy-MM-dd hh:mm:ss" ))+" ")]),_c('div',{staticClass:"companyItemOperition"},[_c('el-link',{staticClass:"unbind",attrs:{"type":"primary"},on:{"click":function($event){return _vm.postDingdingGroupUnbind(item.id)}}},[_vm._v("解绑 ")])],1),_c('div',{staticClass:"bindBot"},[_vm._l((item.bindBot
                                    ? item.bindBot.botInfos
                                    : []),function(cell,cellIndex){return _c('span',{key:cell.botId,staticClass:"bindBotName"},[(cellIndex != 0)?_c('span',[_vm._v("、")]):_vm._e(),_vm._v(_vm._s(cell.name))])}),(
                                    item.bindBot && item.bindBot.count != 0
                                )?_c('span',{staticClass:"editBindBot el-icon-s-tools",staticStyle:{"color":"#366aff"},on:{"click":function($event){return _vm.bindBotV2('eWx', 'edit', item)}}}):_vm._e(),(
                                    item.bindBot == null ||
                                    (item.bindBot &&
                                        item.bindBot.count == 0)
                                )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.bindBotV2('eWx', 'add', item)}}},[_vm._v("请绑定机器人")]):_vm._e()],2),_c('div',{staticClass:"reVisible"},[_c('el-switch',{attrs:{"active-color":"#366AFF","inactive-color":"#A9B3C6"},on:{"change":function($event){return _vm.changeSingSwitch($event, item)}},model:{value:(item.replyVisible),callback:function ($$v) {_vm.$set(item, "replyVisible", $$v)},expression:"item.replyVisible"}})],1)])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        _vm.dingdingGroupList == null ||
                        _vm.dingdingGroupList.length == 0
                    ),expression:"\n                        dingdingGroupList == null ||\n                        dingdingGroupList.length == 0\n                    "}],staticClass:"itemContent"},[_c('br'),_c('p',{staticClass:"null",staticStyle:{"text-align":"center"}},[_vm._v("暂 无")]),_c('br')])]),_c('div',{staticClass:"pagination-content"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-size":_vm.pageSize,"layout":"total, prev, pager, next","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"dingding-group-header-des"},[_c('span',{staticClass:"group-list-title"},[_vm._v("钉钉群模板绑定机器人")]),_c('span',{staticClass:"group-list-des"},[_vm._v("将钉钉群机器人拉进新群后，将默认使用绑定的机器人")])])
}]

export { render, staticRenderFns }